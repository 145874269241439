
export const Detail = ({ payment, token, setIsValid }) => {
	return (
		<form className="px-4 pt-16 pb-36 sm:px-6 lg:col-start-1 lg:row-start-1 lg:px-0 lg:pb-16" method="post" action="https://webpay3gint.transbank.cl/webpayserver/initTransaction">
			<input type="hidden" name="token_ws" value={token} />
			<div className="mx-auto max-w-lg lg:max-w-none">
				<section aria-labelledby="contact-info-heading">
					<h2
						id="contact-info-heading"
						className="text-lg font-medium text-gray-900"
					>
						Información de Contacto
					</h2>

					<div className="mt-6">
						<label
							htmlFor="email-address"
							className="block text-sm font-medium text-gray-700"
						>
							Nombre Completo
						</label> 
						<div className="mt-1">
								<p
									className="w-full sm:text-sm"
								>
									{payment.client.name}
								</p>
						</div>
					</div>
					<div className="mt-6">
						<label
							htmlFor="email-address"
							className="block text-sm font-medium text-gray-700"
						>
							Edad
						</label>
						<div className="mt-1">
								<p
									className="w-full sm:text-sm"
								>
									{payment.client.age}
								</p>
						</div>
					</div>
					<div className="mt-6">
						<label
							htmlFor="email-address"
							className="block text-sm font-medium text-gray-700"
						>
							Teléfono / Whatsapp
						</label>
						<div className="mt-1">
								<p
									className="w-full sm:text-sm"
								>
									{payment.client.phone}
								</p>
						</div>
					</div>
					<div className="mt-6">
						<label
							htmlFor="email-address"
							className="block text-sm font-medium text-gray-700"
						>
							Email
						</label>
						<div className="mt-1">
								<p
									className="w-full sm:text-sm"
								>
									{payment.client.email}
								</p>
						</div>
					</div>
				</section>

				<section aria-labelledby="shipping-heading" className="mt-10">
					<h2
						id="shipping-heading"
						className="text-lg font-medium text-gray-900"
					>
						Dirección
					</h2>

					<div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3">
						<div className="sm:col-span-3">
							<label
								htmlFor="address"
								className="block text-sm font-medium text-gray-700"
							>
								Dirección
							</label>
							<div className="mt-1">
								<p
									className="w-full sm:text-sm"
								>
									{payment.client.address}
								</p>
							</div>
						</div>

						<div className="sm:col-span-3">
							<label
								htmlFor="apartment"
								className="block text-sm font-medium text-gray-700"
							>
								Apartamento, suite, etc.
							</label>
							<div className="mt-1">
								<p
									className="w-full sm:text-sm"
								>
									{payment.client.street}
								</p>
							</div>
						</div>

						<div>
							<label
								htmlFor="city"
								className="block text-sm font-medium text-gray-700"
							>
								Ciudad
							</label>
							<div className="mt-1">
								<p
									className="w-full sm:text-sm"
								>
									{payment.client.city}
								</p>
							</div>
						</div>

						<div>
							<label
								htmlFor="region"
								className="block text-sm font-medium text-gray-700"
							>
								Estado / Provincia
							</label>
							<div className="mt-1">
								<p
									className="w-full sm:text-sm"
								>
									{payment.client.state}
								</p>
							</div>
						</div>
					</div>
				</section>

				<div className="mt-10 border-t border-gray-200 pt-6 sm:flex sm:items-center sm:justify-between">
					<button
						onClick={() => setIsValid(false)}
						className="w-full py-2 px-4 text-sm font-medium sm:order-last sm:ml-6 sm:w-auto"
					>
						Cancelar
					</button>
					<button
						className="w-full rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:order-last sm:ml-6 sm:w-auto"
					>
						Ir a pagar
					</button>
					<p className="mt-4 text-center text-sm text-gray-500 sm:mt-0 sm:text-left">
						El pago se realizará con Webpay.
					</p>
				</div>
			</div>
		</form>
	)
}