import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import bg from "../../images/auth/bg.jpg";
import { useUser } from "../../hooks/useUser";

const SetNewPassword = () => {
  const navigate = useNavigate();

  const { requestReset, emailSchema, loading } = useUser();

  return (
    <div className="flex flex-1 items-center justify-center bg-gradient-to-r from-cyan-500 to-blue-500 h-screen ">
      <div className="bg-white px-4 py-6 sm:p-8 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl mx-auto shadow-lg w-96">
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={emailSchema}
          onSubmit={(values) => {
            requestReset(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form className="space-y-2" onSubmit={handleSubmit}>
              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Ingresa nueva contraseña
                </label>
                <p className="text-slate-500 text-xs">
                  Define tu nueva contraseña
                </p>
                <div className="mt-2">
                  <input
                    type="text"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    placeholder="Ej: correo@gmail.com"
                    id="email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                  />
                  {errors.email && touched.email && (
                    <span className="text-red-600">{errors.email}</span>
                  )}
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Confirma nueva contraseña
                </label>
                <p className="text-slate-500 text-xs">
                  Ingrésala una última vez para confirmar
                </p>
                <div className="mt-2">
                  <input
                    type="text"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    placeholder="Ej: correo@gmail.com"
                    id="email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                  />
                  {errors.email && touched.email && (
                    <span className="text-red-600">{errors.email}</span>
                  )}
                </div>
              </div>
              <div className="h-1" />
              <div className="flex flex-row justify-between">
                <button
                  type="button"
                  onClick={() => navigate("/auth")}
                  className="flex flex-row items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-grey-500 border shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 mr-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 19.5L8.25 12l7.5-7.5"
                    />
                  </svg>
                  Volver
                </button>

                <button
                  type="submit"
                  disabled={loading}
                  className="rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                >
                  Confirmar cambio
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SetNewPassword;
