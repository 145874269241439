import { useState } from "react";
import { Layout } from "../../../components/layout/app";
import { SideBar } from "./side-bar";
import { ColumnTwo } from "./column-two";
import { OpenIssues } from "./open-issues";

const RenderComponent = ({ index, setIntex }) => {
  switch (index) {
    case 0:
      return <ColumnTwo />;
    case 1:
      return <OpenIssues setIndex={setIntex} />;
    default:
      return null;
  }
};

// TODO: Implement <NPSForm />
export const UserDashboard = () => {
  const [index, setIntex] = useState(0);

  return (
    <Layout>
      {console.log("iiii", index)}
      <div className="grid grid-cols-12 gap-8">
        <SideBar index={index} onClick={setIntex} />
        <RenderComponent index={index} setIntex={setIntex} />
      </div>
    </Layout>
  );
};
