import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import Dinero from "dinero.js";

export const OfferModal = ({
  openOffer,
  setOpen,
  issueId,
  reject,
  offer: {
    price = 0,
    id,
    milestones = 1,
    lawyer: { name = "", specialties = [] } = {},
  },
}) => {
  const navigate = useNavigate();
  const parsedPrice = parseInt(price);
  const parsedMilestone = parseInt(milestones);
  const finalPrice = parsedPrice * 100;
  const formattedPrice = Dinero({ amount: finalPrice });
  const pricePerMilestone = formattedPrice.divide(parsedMilestone);

  return (
    <Transition.Root show={openOffer} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className=" relative transform overflow-hidden rounded-xl bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm lg:max-w-xl sm:p-6">
                <div>
                  <div className="mx-auto flex h-14 w-14 items-center justify-center rounded-full">
                    <img
                      className="inline-block h-14 w-14 rounded-full"
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqnIxbQr9wQsYLFnhDhLSaXLhiZeLg_IIdg2OsVXeamQ&s"
                      alt=""
                    />
                  </div>
                  <div className="mt-2 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-semibold leading-6 text-slate-700"
                    >
                      {name}
                    </Dialog.Title>
                    <div className="mt-2 space-x-2">
                      {specialties.map(({ name }) => (
                        <span className="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
                          {name}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <p className="text-sm text-slate-700 font-semibold">
                    Servicio
                  </p>
                  <p className="text-slate-600 mb-2">
                    Resolución de disputas laborales, discriminación en el
                    trabajo y problemas de indemnización.
                  </p>
                </div>
                <div className=" flex flex-col  flex-1 rounded-lg">
                  <div className=" bg-slate-50 p-2 flex flex-col items-center">
                    <p className="text-3xl text-slate-700">
                      {formattedPrice.toFormat("$0.0")}
                    </p>
                    <p className="text-sm text-slate-500 font-semibold rounded-lg">
                      Precio
                    </p>
                  </div>
                  <div className="flex flex-row space-x-2 mt-2">
                    <div className=" bg-slate-50 p-2 flex flex-1 flex-col items-center rounded-lg">
                      <p className="text-md text-slate-500 font-semibold">
                        Etapas
                      </p>
                      <p>{milestones}</p>
                    </div>
                    <div className=" bg-slate-50 p-2 flex flex-1 flex-col items-center rounded-lg">
                      <p className="text-md text-slate-500 font-semibold">
                        Precio por etapa
                      </p>
                      <p>{pricePerMilestone.toFormat("$0.0")}</p>
                    </div>
                  </div>
                </div>

                <p className="text-xs text-center text-slate-500 mt-3">
                  Una vez aceptada la oferta, podrás accederás a los datos del
                  abogado para iniciar el contacto. El abogado también recibirá
                  tus datos de contacto
                </p>

                <div className="mt-3 sm:mt-6 space-y-2">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-emerald-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                    onClick={() =>
                      navigate(`/issues/${issueId}/offers/${id}/checkout`)
                    }
                  >
                    Aceptar oferta
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-white-500 px-3 py-2 text-sm font-semibold text-slate-500 border-2 border-slate-500 shadow-sm hover:border-rose-600 hover:text-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"
                    onClick={() => {
                      reject(id);
                      setOpen(false);
                    }}
                  >
                    Rechazar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
