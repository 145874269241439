/* This example requires Tailwind CSS v3.0+ */
import React from "react";
import Header from "./header";
import Features from "./features";
import Statistics from "./statistics";
import Faq from "./faq";
import Layout from "../../components/layout/marketing";
import CallToActionTwo from "./call-to-action-two";
import SocialProof from "./social-proof";

const LandingPage = () => {
  return (
    <Layout>
      <Header />
      <SocialProof />
      <Features />
      <Statistics />

      <CallToActionTwo />
      <Faq />
    </Layout>
  );
};

export default LandingPage;
