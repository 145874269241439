import axios from "axios";
import {
  fetchUser,
  createUser,
  requestResetUserPassword
} from './users';
import {
  fetchIssues,
  createIssue,
  fetchIssue,
  createIssueComment,
  createIssueOffer,
  fetchOffer,
  updateIssueOffer,
  createPayment,
  fetchPaymentByTransbankToken
} from "./issues";
import { fetchLawyers, fetchLawyer } from "./lawyers";
import { fetchCategories } from "./categories";
import { createPrompt } from "./gpt"

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  const persistedData = JSON.parse(localStorage.getItem("persist:root"));
  const parsedUser = JSON.parse(persistedData.users);
  const {
    current: {
      token,
      selectedProfile: { id }
    }
  } = parsedUser;
  if (token && id) {
    config.headers['Authorization'] = `Bearer ${token}`;
    config.headers['Profile'] = id;
  }
  config.headers['Accept'] = '*/*';
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

export {
  fetchUser,
  fetchIssues,
  fetchIssue,
  fetchCategories,
  createIssue,
  createIssueComment,
  fetchLawyers,
  createIssueOffer,
  fetchOffer,
  updateIssueOffer,
  createPayment,
  createPrompt,
  fetchLawyer,
  fetchPaymentByTransbankToken,
  createUser,
  requestResetUserPassword
};
