import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import failure from "./failure.json";
import success from "./success.json";
import { usePayment } from "../../../hooks/usePayment";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const WebpayResponseHandler = () => {
  const navigate = useNavigate();
  let query = useQuery();
  
  const { transactionStatus } = usePayment(
    query.get('token_ws'),
    () => navigate('/dashboard')
  );

  const showStatus = () => {
    switch (transactionStatus) {
      case true:
        return (
          <>
            <Lottie
              className="w-72 h-72 sm:w-96 sm:h-96"
              animationData={success}
            />
            <p className="text-xl w-64 font-medium text-center text-slate-700">
              Tu pago se ha realizado con éxito
            </p>
            <p className="text-lg w-64 text-center text-slate-600 mt-1 sm:w-96 pb-12">
              Muchas gracias por confiar en nosotros recibir la protección legal
              que necesitabas
            </p>
            <button
              type="button"
              onClick={() => navigate('/dashboard')}
              className="inline-flex items-center gap-x-2 rounded-md bg-emerald-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
            >
              Continuar
              <ChevronRightIcon
                className="-ml-0.5 h-5 w-5"
                aria-hidden="true"
              />
            </button>
          </>
        )
      case false:
        return (
          <>
            <Lottie className="w-60 h-60" animationData={failure} />
            <p className="text-xl w-64 font-medium text-center text-slate-700">
              Hemos tenido problemas para cursar tu pago
            </p>
            <p className="text-lg w-64 text-center text-slate-600 mt-1 sm:w-96 pb-12">
              Intenta tu pago nuevamente, si el problema persiste, consulta con
              tu banco
            </p>
            <button
              type="button"
              onClick={() => navigate('/dashboard')}
              className="inline-flex items-center gap-x-2 rounded-md bg-rose-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"
            >
              Continuar
              <ChevronRightIcon
                className="-ml-0.5 h-5 w-5"
                aria-hidden="true"
              />
            </button>
          </>
        );
      default: 
        return (<></>); // TODO: AGREGAR LOADER
    }
  }

  return (
    <div className="max-w-full h-full bg-slate-100 flex items-center justify-center">
      <div className="bg-white pb-10 px-4 rounded-xl shadow shadow-slate-500/8 drop-shadow-lg flex flex-col items-center sm:p-8">
        {showStatus()}
      </div>
    </div>
  );
};
