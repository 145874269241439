/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import {
  CheckIcon,
  HandThumbUpIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import {
  ChatBubbleBottomCenterTextIcon,
  StarIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

const user = {
  name: "Whitney Francis",
  email: "whitney@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80",
};
const navigation = [
  { name: "Dashboard", href: "#" },
  { name: "Jobs", href: "#" },
  { name: "Applicants", href: "#" },
  { name: "Company", href: "#" },
];
const breadcrumbs = [
  { name: "Jobs", href: "#", current: false },
  { name: "Front End Developer", href: "#", current: false },
  { name: "Applicants", href: "#", current: true },
];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];
const attachments = [
  { name: "factura-clinica.pdf", href: "#" },
  { name: "costo-tratamientos.pdf", href: "#" },
];
const eventTypes = {
  open: { icon: StarIcon, bgColorClass: "bg-slate-400" },
  comment: {
    icon: ChatBubbleBottomCenterTextIcon,
    bgColorClass: "bg-blue-500",
  },
  offer: { icon: StarIcon, bgColorClass: "bg-green-500" },
  started: { icon: StarIcon, bgColorClass: "bg-green-500" },
  updated: { icon: CheckIcon, bgColorClass: "bg-green-500" },
};
const timeline = [
  {
    id: 1,
    type: eventTypes.open,
    content: "El caso fue abierto por",
    target: "Carolina Morales",
    date: "Sep 20",
    datetime: "2020-09-20",
  },
  {
    id: 2,
    type: eventTypes.comment,
    content: "Recibió un comentario de ",
    target: "Javier López",
    date: "Sep 22",
    datetime: "2020-09-22",
  },
  {
    id: 3,
    type: eventTypes.comment,
    content: "Recibió un comentario de ",
    target: "Úrsula Pradenas",
    date: "Sep 28",
    datetime: "2020-09-28",
  },
  {
    id: 4,
    type: eventTypes.comment,
    content: "Recibió un comentario de ",
    target: "Matías Silva",
    date: "Sep 30",
    datetime: "2020-09-30",
  },
  {
    id: 5,
    type: eventTypes.offer,
    content: "Oferta de ",
    target: "Katherine Ferreira",
    date: "Oct 4",
    datetime: "2020-10-04",
  },
];
const comments = [
  {
    id: 1,
    name: "Leslie Morales",
    date: "4d ago",
    imageId: "1494790108377-be9c29b29330",
    body: "Acabo de leer su historia y me siento profundamente consternado por lo que ha tenido que pasar. Me gustaría saber más acerca de su caso para poder ayudarlo de la mejor manera posible. ¿Podría proporcionarme más información sobre los detalles del accidente y los gastos médicos incurridos? También sería útil conocer más sobre las lesiones que ha sufrido y cómo han afectado su vida diaria. ",
  },
  {
    id: 2,
    name: "Miguel Lopez",
    date: "4d ago",
    imageId: "1519244703995-f4e0f30006d5",
    body: "He leído su historia y estoy muy interesado en tomar su caso. Me gustaría programar una reunión con usted lo antes posible para discutir en detalle su situación y conocer más sobre sus necesidades legales. Necesitaría una lista completa de los gastos médicos incurridos y una descripción detallada de las lesiones que ha sufrido. También me gustaría saber si ha tomado algún tipo de acción legal antes y si ha tenido contacto con la compañía de seguros del otro conductor.",
  },
  {
    id: 3,
    name: "Dries Vincent",
    date: "4d ago",
    imageId: "1506794778202-cad84cf45f1d",
    body: "Lamento mucho lo que ha tenido que pasar y entiendo lo difícil que debe ser para usted en este momento. Como abogado especializado en casos de accidentes automovilísticos, estoy comprometido a ayudarlo en todo lo que pueda. Me gustaría saber más sobre el accidente y si ha habido algún desarrollo desde que se identificó al conductor. Además, necesitaría una copia de cualquier informe policial y registro médico disponible. ",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Details = () => {
  const navigate = useNavigate();
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-slate-100">
        <body class="h-full">
        ```
      */}
      <div className="min-h-full">
        <main className="py-10">
          {/* Page header */}
          <div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    className="h-16 w-16 rounded-full"
                    src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                    alt=""
                  />
                  <span
                    className="absolute inset-0 rounded-full shadow-inner"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-slate-900">
                  Ricardo Solar
                </h1>
                <p className="text-sm font-medium text-slate-500">
                  Creo este caso en{" "}
                  <time dateTime="2020-08-25">Agosto 25, 2020</time>
                </p>
              </div>
            </div>

            <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
              {" "}
              <div className="flex bg-white rounded-md shadow-sm p-4">
                <img
                  className="h-10 w-10 rounded-full"
                  src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
                <div className="ml-3">
                  <p className="text-md font-medium text-gray-900">
                    Juan Ortuzar
                  </p>
                  <p className="text-sm text-gray-500">
                    +59 9 9210 22 11 · juanpz@gmail.com
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-span-2 lg:col-start-1">
              {/* Description list*/}
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2
                      id="applicant-information-title"
                      className="text-lg font-medium leading-6 text-slate-900"
                    >
                      Información del caso
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-slate-500">
                      Detalles y adjuntos.
                    </p>
                  </div>
                  <div className="border-t border-slate-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-slate-500">
                          Acerca del caso
                        </dt>
                        <dd className="mt-1 text-sm text-slate-900">
                          <p className="mb-4">
                            Hace algunas semanas fui víctima de un accidente
                            automovilístico. Fue algo aterrador. El conductor
                            del otro vehículo se dio a la fuga, pero gracias a
                            las cámaras de seguridad del lugar, pudieron
                            identificarlo.
                          </p>
                          <p className="mb-4">
                            Fui llevado de emergencia al hospital, donde recibí
                            atención médica inmediata. Los gastos médicos fueron
                            muy altos, y desafortunadamente, no tengo el dinero
                            para pagarlos. Además, me encuentro en proceso de
                            recuperación y he perdido varios días de trabajo, lo
                            que ha afectado mi economía. Por esta razón, estoy
                            en busca de un abogado que me pueda ayudar a
                            reclamar los gastos médicos y los daños sufridos en
                            el accidente.
                          </p>
                          <p className="mb-4">
                            Sé que el conductor del otro vehículo debe asumir su
                            responsabilidad y pagar por los daños causados.
                            Espero poder contar con la ayuda de un abogado
                            competente y experimentado que pueda guiarme a
                            través del proceso legal y ayudarme a obtener la
                            compensación que merezco.
                          </p>
                          <p className="mb-4">
                            Por favor, si alguien puede ayudarme, no dude en
                            ponerse en contacto conmigo. Estoy desesperado por
                            encontrar una solución a mi situación actual y
                            volver a mi vida normal.
                          </p>
                        </dd>
                      </div>

                      <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-slate-500">
                          Adjuntos
                        </dt>
                        <dd className="mt-1 text-sm text-slate-900">
                          <ul
                            role="list"
                            className="divide-y divide-slate-200 rounded-md border border-slate-200"
                          >
                            {attachments.map((attachment) => (
                              <li
                                key={attachment.name}
                                className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                              >
                                <div className="flex w-0 flex-1 items-center">
                                  <PaperClipIcon
                                    className="h-5 w-5 flex-shrink-0 text-slate-400"
                                    aria-hidden="true"
                                  />
                                  <span className="ml-2 w-0 flex-1 truncate">
                                    {attachment.name}
                                  </span>
                                </div>
                                <div className="ml-4 flex-shrink-0">
                                  <a
                                    href={attachment.href}
                                    className="font-medium text-blue-600 hover:text-blue-500"
                                  >
                                    Descargar
                                  </a>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </section>

              {/* Comments*/}
              <section aria-labelledby="notes-title">
                <div className="bg-white shadow sm:overflow-hidden sm:rounded-lg">
                  <div className="divide-y divide-slate-200">
                    <div className="px-4 py-5 sm:px-6">
                      <h2
                        id="notes-title"
                        className="text-lg font-medium text-slate-900"
                      >
                        Notes
                      </h2>
                    </div>
                    <div className="px-4 py-6 sm:px-6">
                      <ul role="list" className="space-y-8">
                        {comments.map((comment) => (
                          <li key={comment.id}>
                            <div className="flex space-x-3">
                              <div className="flex-shrink-0">
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={`https://images.unsplash.com/photo-${comment.imageId}?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80`}
                                  alt=""
                                />
                              </div>
                              <div>
                                <div className="text-sm">
                                  <a
                                    href="/lawyer-profile"
                                    className="font-medium text-slate-900"
                                  >
                                    {comment.name}
                                  </a>
                                </div>
                                <div className="mt-1 text-sm text-slate-700">
                                  <p>{comment.body}</p>
                                </div>
                                <div className="mt-2 space-x-2 text-sm">
                                  <span className="font-medium text-slate-500">
                                    {comment.date}
                                  </span>{" "}
                                  <span className="font-medium text-slate-500">
                                    &middot;
                                  </span>{" "}
                                  <button
                                    type="button"
                                    className="font-medium text-slate-600 rounded-md bg-slate-200 pt-1 pb-1 pl-2 pr-2"
                                  >
                                    Contestar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="bg-slate-50 px-4 py-6 sm:px-6">
                    <div className="flex space-x-3">
                      <div className="flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-full"
                          src={user.imageUrl}
                          alt=""
                        />
                      </div>
                      <div className="min-w-0 flex-1">
                        <form action="#">
                          <div>
                            <label htmlFor="comment" className="sr-only">
                              About
                            </label>
                            <textarea
                              id="comment"
                              name="comment"
                              rows={3}
                              className="block w-full rounded-md border-0 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:py-1.5 sm:text-sm sm:leading-6"
                              placeholder="Añade tu comentario aquí"
                              defaultValue={""}
                            />
                          </div>
                          <div className="mt-3 flex items-center justify-between">
                            <button
                              type="submit"
                              className="inline-flex items-center justify-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                            >
                              Responder
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <section
              aria-labelledby="timeline-title"
              className="lg:col-span-1 lg:col-start-3"
            >
              <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                <h2
                  id="timeline-title"
                  className="text-lg font-medium text-slate-900"
                >
                  Línea de eventos
                </h2>

                {/* Activity Feed */}
                <div className="mt-6 flow-root">
                  <ul role="list" className="-mb-8">
                    {timeline.map((item, itemIdx) => (
                      <li key={item.id}>
                        <div className="relative pb-8">
                          {itemIdx !== timeline.length - 1 ? (
                            <span
                              className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-slate-200"
                              aria-hidden="true"
                            />
                          ) : null}
                          <div className="relative flex space-x-3">
                            <div>
                              <span
                                className={classNames(
                                  item.type.bgColorClass,
                                  "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                                )}
                              >
                                <item.type.icon
                                  className="h-5 w-5 text-white"
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                            <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                              <div>
                                <p className="text-sm text-slate-500">
                                  {item.content}{" "}
                                  <a
                                    href="#"
                                    className="font-medium text-slate-900"
                                  >
                                    {item.target}
                                  </a>
                                </p>
                              </div>
                              <div className="whitespace-nowrap text-right text-sm text-slate-500">
                                <time dateTime={item.datetime}>
                                  {item.date}
                                </time>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="justify-stretch mt-6 flex flex-col">
                  <button
                    onClick={() => navigate("/lawyer/create-offer")}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                  >
                    Crear oferta
                  </button>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};
