import {
  CalendarIcon,
  UsersIcon,
  ChatBubbleOvalLeftEllipsisIcon,
} from "@heroicons/react/20/solid";
import moment from "moment";
import { useIssues } from "../../../hooks/useIssues";
import notCasesFound from "../../../images/not-found/notCasesFound.jpg";

export const OpenIssues = ({ setIndex }) => {
  const { openIssues } = useIssues();

  const getStatusColor = (status) => {
    switch (status) {
      case "created":
        return (
          <p className="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">
            {" "}
            Pendiente{" "}
          </p>
        );
      case "assigned":
        return (
          <p className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
            {" "}
            Asignado{" "}
          </p>
        );
      case "close":
        return (
          <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
            {" "}
            Cerrado{" "}
          </p>
        );
      default:
        return (
          <p className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
            {" "}
            Desconocido{" "}
          </p>
        );
    }
  };

  if (openIssues.length === 0)
    return (
      <div className="col-span-9 flex flex-col items-center justify-center">
        <img className="max-w-md" src={notCasesFound}></img>
        <p className="text-xl font-bold mt-2 text-slate-700">
          No tienes casos abiertos
        </p>
        <p className="text-md text-slate-500">
          Comienza revisando los casos abiertos por nuestra comunidad
        </p>
        <div
          onClick={() => setIndex(0)}
          className="mt-4 rounded-md bg-sky-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
        >
          Abrir un nuevo caso
        </div>
      </div>
    );

  return (
    <div className="col-span-9">
      <div className="overflow-hidden bg-white shadow sm:rounded-md">
        <ul className="divide-y divide-gray-200">
          {openIssues.map((issue) => (
            <li key={issue.id}>
              <a
                href={`/issue-detail/${issue.id}`}
                className="block hover:bg-gray-50"
              >
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <p className="truncate text-sm font-medium text-sky-600">
                      {issue.title}
                      <br />
                      {issue.text}
                    </p>
                    <div className="ml-2 flex flex-shrink-0">
                      {getStatusColor(issue.status)}
                    </div>
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <p className="flex items-center text-sm mr-1 text-gray-500">
                        <UsersIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        {issue.interactions}
                      </p>
                      <p className="flex items-center text-sm text-gray-500">
                        <ChatBubbleOvalLeftEllipsisIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        {issue.comments}
                      </p>
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <CalendarIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <p>
                        Creado{" "}
                        <time>
                          {moment(issue.created_at).format("YYYY-MM-DD")}
                        </time>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
