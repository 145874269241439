import { useState, useEffect } from "react";
import { fetchLawyer } from "../api";

export const useLawyer = (id, callbackError) => {
  const [lawyer, setLawyer] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await fetchLawyer(id);

      if(!error){
        setLawyer(data);
      } else {
        callbackError();
      }
    }

    fetchData();
  }, [id]);

  return {
    lawyer
  }
}
