import { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { useParams } from "react-router-dom";
import logo from "../../../images/logo.png";
import { usePayment } from "../../../hooks/usePayment";
import { useIssue } from "../../../hooks/useIssue";
import { Detail } from "./detail";

export const Form = () => {
  const [offer, setOffer] = useState({});
  const { issueId, offerId } = useParams();

  const {
    issue: {
      offers = []
    }
  } = useIssue(issueId);

  useEffect(() => {
    setOffer(offers.find((of) => of.id === parseInt(offerId)));
  }, [offerId, offers]);

  const {
    payment,
    setPayment,
    error,
    isValid,
    validate,
    token,
    setIsValid
  } = usePayment();

  return (
    <div className="bg-white">
      <div
        className="fixed top-0 left-0 hidden h-full w-1/2 bg-white lg:block"
        aria-hidden="true"
      />
      <div
        className="fixed top-0 right-0 hidden h-full w-1/2 bg-gray-50 lg:block"
        aria-hidden="true"
      />

      <div className="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 xl:gap-x-48">
        <h1 className="sr-only">Información de la compra</h1>

        <section
          aria-labelledby="summary-heading"
          className="bg-gray-50 px-4 pt-16 pb-10 sm:px-6 lg:col-start-2 lg:row-start-1 lg:bg-transparent lg:px-0 lg:pb-16"
        >
          <div className="mx-auto max-w-lg lg:max-w-none">
            <h2
              id="summary-heading"
              className="text-lg font-medium text-gray-900"
            >
              Resumen del pedido
            </h2>

            <ul
              className="divide-y divide-gray-200 text-sm font-medium text-gray-900"
            >
              <li
                className="flex items-start space-x-4 py-6"
              >
                <img
                  src={logo}
                  alt="Denu"
                  className="h-20 w-20 flex-none rounded-md object-cover object-center"
                />
                <div className="flex-auto space-y-1">
                  <h3>{offer?.lawyer?.name}</h3>
                  <p className="text-gray-500">{offer?.service}</p>
                  <p className="text-gray-500">{offer?.milestones}</p>
                </div>
                <p className="flex-none text-base font-medium">
                  ${offer?.price}
                </p>
              </li>
            </ul>

            <dl className="hidden space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-900 lg:block">
              <div className="flex items-center justify-between">
                <dt className="text-gray-600">Subtotal</dt>
                <dd>$70.00</dd>
              </div>

              <div className="flex items-center justify-between">
                <dt className="text-gray-600">Tarifa servicio</dt>
                <dd>$1.000</dd>
              </div>

              <div className="flex items-center justify-between">
                <dt className="text-gray-600">Comisión financiera</dt>
                <dd>$300</dd>
              </div>

              <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                <dt className="text-base">Total</dt>
                <dd className="text-base">${offer?.price}</dd>
              </div>
            </dl>

            <Popover className="fixed inset-x-0 bottom-0 flex flex-col-reverse text-sm font-medium text-gray-900 lg:hidden">
              <div className="relative z-10 border-t border-gray-200 bg-white px-4 sm:px-6">
                <div className="mx-auto max-w-lg">
                  <Popover.Button className="flex w-full items-center py-6 font-medium">
                    <span className="mr-auto text-base">Total</span>
                    <span className="mr-2 text-base">$361.80</span>
                    <ChevronUpIcon
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                  </Popover.Button>
                </div>
              </div>

              <Transition.Root as={Fragment}>
                <div>
                  <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Popover.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
                  </Transition.Child>

                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="translate-y-full"
                    enterTo="translate-y-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-y-0"
                    leaveTo="translate-y-full"
                  >
                    <Popover.Panel className="relative bg-white px-4 py-6 sm:px-6">
                      <dl className="mx-auto max-w-lg space-y-6">
                        <div className="flex items-center justify-between">
                          <dt className="text-gray-600">Subtotal</dt>
                          <dd>$320.00</dd>
                        </div>

                        <div className="flex items-center justify-between">
                          <dt className="text-gray-600">Shipping</dt>
                          <dd>$15.00</dd>
                        </div>

                        <div className="flex items-center justify-between">
                          <dt className="text-gray-600">Taxes</dt>
                          <dd>$26.80</dd>
                        </div>
                      </dl>
                    </Popover.Panel>
                  </Transition.Child>
                </div>
              </Transition.Root>
            </Popover>
          </div>
        </section>

        { isValid ? (
          <Detail token={token} payment={payment} setIsValid={setIsValid}/>
        ) : (
          <div className="px-4 pt-16 pb-36 sm:px-6 lg:col-start-1 lg:row-start-1 lg:px-0 lg:pb-16">
            <div className="mx-auto max-w-lg lg:max-w-none">
              <section aria-labelledby="contact-info-heading">
                <h2
                  id="contact-info-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  Información de Contacto
                </h2>

                <div className="mt-6">
                  <label
                    className={`${error && payment.client.name === '' && "text-red-600"} block text-sm font-medium text-gray-700`}
                  >
                    Nombre Completo
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={payment.client.name}
                      onChange={({ target: { value } }) => setPayment({ ...payment, client: { ...payment.client, name: value } })}
                      className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm ${error && payment.client.name === '' && "border-red-600"}`}
                    />
                  </div>
                    { error && payment.client.name === '' && (
                      <span className="text-red-600">Ingresa un nombre</span>
                    )}
                </div>
                <div className="mt-6">
                  <label
                    className={`${error && payment.client.age === '' && "text-red-600"} block text-sm font-medium text-gray-700`}
                  >
                    Edad
                  </label>
                  <div className="mt-1">
                    <input
                      type="number"
                      value={payment.client.age}
                      onChange={({ target: { value } }) => setPayment({ ...payment, client: { ...payment.client, age: value }})}
                      className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm ${error && payment.client.age === '' && "border-red-600"}`}
                    />
                  </div>
                    { error && payment.client.age === '' && (
                      <span className="text-red-600"> Ingresa tu edad</span>
                    )}
                </div>
                <div className="mt-6">
                  <label
                    className={`${error && payment.client.phone === '' && "text-red-600"} block text-sm font-medium text-gray-700`}
                  >
                    Teléfono / Whatsapp
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={payment.client.phone}
                      onChange={({ target: { value } }) => setPayment({ ...payment, client: { ...payment.client, phone: value } })}
                      className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm ${error && payment.client.phone === '' && "border-red-600"}`}
                    />
                  </div>
                    { error && payment.client.phone === '' && (
                      <span className="text-red-600"> Ingresa un telefono</span>
                    )}
                </div>
                <div className="mt-6">
                  <label
                    className={`${error && payment.client.email === '' && "text-red-600"} block text-sm font-medium text-gray-700`}
                  >
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={payment.client.email}
                      onChange={({ target: { value } }) => setPayment({ ...payment, client: { ...payment.client, email: value } })}
                      className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm ${error && payment.client.email === '' && "border-red-600"}`}
                    />
                  </div>
                  { error && payment.client.email === '' && (
                       <span className="text-red-600"> Ingresa un correo electronico</span>
                    )}
                </div>
              </section>

              <section aria-labelledby="shipping-heading" className="mt-10">
                <h2
                  id="shipping-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  Dirección
                </h2>

                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3">
                  <div className="sm:col-span-3">
                    <label
                     className={`${error && payment.client.address === '' && "text-red-600"} block text-sm font-medium text-gray-700`}
                    >
                      Dirección
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        value={payment.client.address}
                        onChange={({ target: { value } }) => setPayment({ ...payment, client: { ...payment.client, address: value } })}
                        className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm ${error && payment.client.address === '' && "border-red-600"}`}
                      />
                    </div>
                    { error && payment.client.address === '' && (
                      <span className="text-red-600"> Ingresa una dirección</span>
                    )}
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      className="block text-sm font-medium text-gray-700"
                    >
                      Apartamento, suite, etc.
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        value={payment.client.stret}
                        onChange={({ target: { value } }) => setPayment({ ...payment, client: { ...payment.client, street: value } })}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                     className={`${error && payment.client.city === '' && "text-red-600"} block text-sm font-medium text-gray-700`}
                    >
                      Ciudad
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        value={payment.client.city}
                        onChange={({ target: { value } }) => setPayment({ ...payment, client: { ...payment.client, city: value } })}
                        className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm ${error && payment.client.city === '' && "border-red-600"}`}
                      />
                    </div>
                    { error && payment.client.city === '' && (
                      <span className="text-red-600"> Ingresa una ciudad</span>
                    )}
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      className={`${error && payment.client.state === '' && "text-red-600"} block text-sm font-medium text-gray-700`}
                    >
                      Estado / Provincia
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        value={payment.client.state}
                        onChange={({ target: { value } }) => setPayment({ ...payment, client: { ...payment.client, state: value }  })}
                        className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm ${error && payment.client.state === '' && "border-red-600"}`}
                      />
                    </div>
                    { error && payment.client.state === '' && (
                      <span className="text-red-600"> Ingresa un estado o provincia</span>
                    )}
                  </div>
                </div>
              </section>

              <div className="mt-10 border-t border-gray-200 pt-6 sm:flex sm:items-center sm:justify-between">
                <button
                  onClick={() => validate(issueId, offerId)}
                  className="w-full rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:order-last sm:ml-6 sm:w-auto"
                >
                  Continue
                </button>
                <p className="mt-4 text-center text-sm text-gray-500 sm:mt-0 sm:text-left">
                  El pago se realizará con Webpay.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
