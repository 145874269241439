const plans = [
  {
    id: "small",
    name: "Familia",
  },
  {
    id: "medium",
    name: "Civil",
  },
  {
    id: "large",
    name: "Penal",
  },
];

export const RadioInput = ({ options = plans }) => {
  return (
    <fieldset>
      <legend className="sr-only">Plan</legend>
      <div className="flex flex-row space-x-2 flex-wrap">
        {options.map((plan) => (
          <div key={plan.id} className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id={plan.id}
                aria-describedby={`${plan.id}-description`}
                name="plan"
                type="radio"
                defaultChecked={plan.id === "small"}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label htmlFor={plan.id} className="font-medium text-gray-900">
                {plan.name}
              </label>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
};
