import { useState, useEffect } from "react"
import { createPayment, fetchPaymentByTransbankToken } from "../api";

export const usePayment = (transbankToken = null, callbackError) => {
  const [payment, setPayment] = useState({
    client: {
      name: 'Daniel Jesus Guzzo Perez',
      age: '31',
      phone: '+56965509442',
      email: 'danieljesusguzzoperez',
      address: 'Los Aromos',
      city: 'Temuco',
      state: 'La Araucanía',
      street: ''
    }
  });
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [token, setToken] = useState('');

  const validate = (issueId, offerId) => {
    const {
      client: {
        name,
        age,
        phone,
        email,
        address,
        city,
        state
      }
    } = payment;
    if(name === '' || age === '' || phone === '' || email === '' || address === '' || city === '' || state === '') {
      setIsValid(false);
      setError(true);
    } else {
      setIsValid(true);
      create(issueId, offerId);
      setError(false);
    }
  }

  const create = async (issueId, offerId) => {
    const { data, error } = await createPayment(issueId, offerId, {
      client_attributes: payment.client
    });

    if(!error) {
      setToken(data.transaction_id);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await fetchPaymentByTransbankToken(transbankToken);

      if(!error){
        setPayment(data);
        setTransactionStatus(true);
      } else if(data.message === 'transaction_cant_be_confirmed') {
        setTransactionStatus(false);
      } else {
        callbackError();
      }
    }

    if (transbankToken) fetchData();
  }, [transbankToken]);

  return {
    payment,
    setPayment,
    loading,
    validate,
    error,
    isValid,
    token,
    setIsValid,
    transactionStatus
  }
}
