import { useNavigate, useParams } from "react-router-dom";
import { useIssue } from "../../../hooks/useIssue";
import { useOffer } from "../../../hooks/useOffer";
import { OfferDetails } from "./offer-details";
import { useCategories } from "../../../hooks/useCategories";
import { LawyerList } from "../update-offer/lawyer-list";

export const CreateOffer = () => {
  const navigate = useNavigate();
  const { issueId } = useParams();

  const {
    issue: { status },
    fetchError,
    isFetched,
    isLawyerAvailableToOffer,
  } = useIssue(issueId);

  const { categories } = useCategories();

  const { offer, setOffer, offerError, create } = useOffer(issueId, categories);

  if (
    (status !== "created" && isFetched) ||
    isLawyerAvailableToOffer() ||
    fetchError
  ) {
    navigate("/lawyer/dashboard");
  }

  return (
    <div className="space-y-10 divide-y divide-gray-900/10">
      <div className="grid grid-cols-1 gap-y-8 gap-x-8 pt-10 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Información Del Servicio
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Ayúdanos a construir tu oferta para que el cliente pueda pagarla
          </p>
        </div>

        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-y-8 gap-x-6 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="service"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Servicio
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="service"
                    id="service"
                    onChange={({ target: { value } }) =>
                      setOffer({ ...offer, service: value })
                    }
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                  />
                  {offerError && offer.service === "" && (
                    <span>Ingrese un servicio</span>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Valor
                </label>
                <div className="mt-2">
                  <input
                    type="number"
                    name="price"
                    id="price"
                    value={offer.price}
                    onChange={({ target: { value } }) =>
                      setOffer({ ...offer, price: value })
                    }
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                  />
                  {offerError && offer.price === "" && (
                    <span>Ingrese un precio</span>
                  )}
                </div>
                <small>
                  Nota: No ingreses decimales ni separadores de miles
                </small>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="service"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Añade más profesionales
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="service"
                    id="service"
                    onChange={({ target: { value } }) =>
                      setOffer({ ...offer, service: value })
                    }
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                  />
                  {offerError && offer.service === "" && (
                    <span>Ingrese un servicio</span>
                  )}
                </div>
              </div>

              <LawyerList />

              <div className="sm:col-span-4">
                <label
                  htmlFor="type"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Tipo de caso
                </label>
                <div className="mt-2">
                  <select
                    id="type"
                    name="type"
                    onChange={({ target: { value } }) =>
                      setOffer({ ...offer, categoryId: value })
                    }
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    {categories.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Hitos de pago
                </label>
                <div className="mt-2">
                  <input
                    type="number"
                    name="milestones"
                    id="milestones"
                    value={offer.milestones}
                    onChange={({ target: { value } }) =>
                      setOffer({ ...offer, milestones: value })
                    }
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                  />
                  {offerError && offer.milestones === "" && (
                    <span>Ingrese los hitos de pagos</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <OfferDetails offer={offer} />
          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 py-4 px-4 sm:px-8">
            <button
              type="button"
              onClick={() => navigate(`/lawyer/issue-detail/${issueId}`)}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancelar
            </button>
            <button
              onClick={() => create()}
              className="rounded-md bg-cyan-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
            >
              Continuar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
