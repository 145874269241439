import { Layout } from "../../../components/layout/app";
import { LawyerList } from "./lawyer-list";

export const Lawyers = () => {
  return (
    <Layout>
      <div className="flex flex-col items-center">
        <LawyerList />
      </div>
    </Layout>
  );
};
