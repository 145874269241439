import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useLawyers } from "../../../hooks/useLawyers";
import { SearchBar } from "../../../components/search-bar";
import { DropDown } from "../../../components/dropdown";
import noLawyers from "../../../images/no-lawyers.jpg";

export const LawyerList = () => {
  const navigate = useNavigate();
  const { lawyers, search, setSearch } = useLawyers();

  return (
    <>
      <div className="flex items-center flex-row justify-between items-start  w-full">
        <div className="flex flex-start flex-col">
          <p className="text-3xl font-semibold mb-2 text-slate-800">
            Abogados de la comunidad{" "}
          </p>
          <p className="text-md font-medium mb-4 max-w-lg text-slate-400">
            Conoce mas sobre las personas detras de las soluciones
          </p>
        </div>
        <div>
          <SearchBar search={search} onSearch={setSearch} />
          <div className="flex space-x-1">
            <DropDown
              filterLabel="Universidades"
              values={["Adolfo Ibañez", "U. de Chile"]}
            />
            <DropDown
              filterLabel="Especialidad"
              values={["Derecho Penal", "Derecho Civil"]}
            />
            <DropDown
              filterLabel="Años de Experiencia"
              values={[5, 6, 7, 10]}
            />
          </div>
        </div>
      </div>
      <div className="h-4" />

      {lawyers.length === 0 && (
        <div className="flex flex-col max-w-screen-xl items-center space-y-3">
          <img src={noLawyers} className="w-96" />
          <p className="text-2xl font-bold text-center">
            Ups! No lo encontramos
          </p>
          <p className="text-slate-500 text-center w-80">
            No hay resultados que coincidan con tu búsqueda, intenta con otros
            parámetros
          </p>
        </div>
      )}
      {lawyers.length >= 1 && (
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {lawyers.map(
            ({
              id,
              description,
              specialties,
              start_year,
              profile: { name } = {},
            }) => (
              <li
                onClick={() => navigate(`/lawyer-profile/${id}`)}
                key={id}
                className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
              >
                <div className="flex flex-1 flex-col p-8">
                  <img
                    className="mx-auto h-32 w-32 flex-shrink-0 rounded-full"
                    src="https://randomuser.me/api/portraits/women/7.jpg"
                    alt=""
                  />
                  <h3 className="mt-6 text-sm font-medium text-gray-900">
                    {name}
                  </h3>
                  <dl className="mt-1 flex flex-grow flex-col justify-between">
                    <dt className="sr-only">Title</dt>
                    <dd className="text-sm text-gray-500">{description}</dd>
                    <dt className="sr-only">Role</dt>
                    <span className="rounded-full px-2 py-1 text-xs font-medium text-slate-800">
                      Experiencia desde {moment(start_year).fromNow()}
                    </span>
                    <dd className="mt-3">
                      {specialties.map(({ id, name }) => (
                        <span
                          key={id}
                          className="mr-2 mt-2 rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800"
                        >
                          {name}
                        </span>
                      ))}
                    </dd>
                  </dl>
                </div>
                <div>
                  <div className="-mt-px flex divide-x divide-gray-200">
                    <div className="flex w-0 flex-1">
                      <a className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                        <EnvelopeIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        Contactar
                      </a>
                    </div>
                    <div className="-ml-px flex w-0 flex-1">
                      <a className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                        <PhoneIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        Llamar
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            )
          )}
        </ul>
      )}
    </>
  );
};
