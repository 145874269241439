import { useSelector } from "react-redux";
import {
  HomeIcon,
} from "@heroicons/react/24/outline";

//{
//  name: "Pagos",
//  href: "#",
//  icon: CalendarIcon,
//},
//{ name: "Cuenta", href: "#", icon: InboxIcon, current: false },


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// TODO: filter issues by created and assigned status
export const SideBar = ({ onClick, index }) => {
  const navigation = [
    {
      name: "Casos Adjudicados",
      href: "/lawyer/dashboard",
      icon: HomeIcon
    },
  ];
  return (
    <div className="col-span-3">
      {navigation.map((item, i) => (
        <a
          key={item.name}
          onClick={() => onClick(i)}
          href={item.href}
          className={classNames(
            i === index
              ? "bg-gray-200 text-gray-900"
              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
            "flex items-center rounded-md px-3 py-2 text-sm font-medium"
          )}
          aria-current={i === index ? "page" : undefined}
        >
          <item.icon
            className={classNames(
              i === index ? "text-gray-500" : "text-gray-400",
              "-ml-1 mr-3 h-6 w-6 flex-shrink-0"
            )}
            aria-hidden="true"
          />
          <span className="truncate">{item.name}</span>
          {item.count ? (
            <span
              className={classNames(
                item.current ? "bg-gray-50" : "bg-gray-200 text-gray-600",
                "ml-auto inline-block rounded-full py-0.5 px-3 text-xs"
              )}
            >
              {item.count}
            </span>
          ) : null}
        </a>
      ))}
    </div>
  );
};
