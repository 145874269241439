import { Layout } from "../../../components/layout/app";
import { Details } from "./details";

export const IssueDetail = () => {
  return (
    <Layout>
      <Details />
    </Layout>
  );
};
