import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import "yup-phone-lite";
import { store, setUser } from "../store";
import { fetchUser, createUser, requestResetUserPassword } from "../api";

export const useUser = (regions = []) => {
  const userState = useSelector(state => state.users.current);
  const isRehydrated = useSelector(state => state._persist?.rehydrated);
  const [user, setCurrentUser] = useState(userState);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCurrentUser(userState);
  }, [userState]);

  const emailSchema = Yup.object().shape({
    email: Yup.string()
      .email('Dirección de correo inválida')
      .required('Ingrese una dirección de correo')
  });

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Dirección de correo inválida')
      .required('Ingrese una dirección de correo'),
    name: Yup.string()
      .required('Ingrese un nombre'),
    lastName: Yup.string()
      .required('Ingrese un apellido'),
    street: Yup.string()
      .required('Ingrese una dirección'),
    phone: Yup.string()
      .phone("CL", "Ingrese un número de teléfono valido")
      .required('Ingrese un número de teléfono'),
    password: Yup.string().required('Ingrese una contraseña'),
    passwordConfirmation: Yup.string()
      .required('Repita su contraseña')
      .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
    state: Yup.string()
      .required('Seleccione una región')
      .oneOf(regions)
  });

  const initialValues = {
    email: '',
    name: '',
    lastName: '',
    phone: '',
    street: '',
    password: '',
    passwordConfirmation: '',
    state: ''
  };

  const create = async (values) => {
    setLoading(true);
    setError("");

    const { data, error } = await createUser({
      ...values,
      phone: `+56${values.phone}`,
      last_name: values.lastName
    });
    if (error) {
      const isEmailTaken = data.message['email']?.find((message) => message === 'has already been taken');
      if (isEmailTaken){
        setError("Este email se encuentra en uso");
      }
    } else {
      store.dispatch(setUser(data));
    };

    setLoading(false);
  }

  const requestReset = async (values) => {
    setLoading(true);
    setError("");

    const { data, error } = await requestResetUserPassword();
    if (error) {
      // Handle error
    } else {
      store.dispatch(setUser(data));
    };

    setLoading(false);
  }

  const getUser = async () => {
    setLoading(true);
    setError("");

    const { data, error } = await fetchUser(user);
    if (error) {
      setError("Credenciales Invalidas");
    } else {
      store.dispatch(setUser(data));
    };

    setLoading(false);
  };

  return {
    user,
    setCurrentUser,
    getUser,
    error,
    loading,
    isRehydrated,
    create,
    schema,
    initialValues,
    emailSchema,
    requestReset
  };
}
