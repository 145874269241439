import { combineReducers } from "redux";
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { createSlice, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
});

const persistConfig = {
  key: 'root',
  storage,
};

const userSlice = createSlice({
  name: 'users',
  initialState: {
    current: {
      email: '',
      profiles: [],
      selectedProfile: {}
    }
  },
  reducers: {
    setUser: (state, action) => {
      const { payload } = action;
      state.current = payload;
      state.current.selectedProfile = payload.profiles[0];
    },
    signOut: (state, action) => {
      state.current = {
        email: '',
        profiles: [],
        selectedProfile: {}
      };
    },
  }
});

const rootReducer = combineReducers({
  users: userSlice.reducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const { setUser, signOut } = userSlice.actions;

export const store = configureStore({
  reducer: persistedReducer,
  middleware: customizedMiddleware
});

export const persistor = persistStore(store);

