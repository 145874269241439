import moment from "moment";
import "moment/locale/es";
import {
  ChatBubbleBottomCenterTextIcon,
  StarIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { OfferModal } from "./offer";
import { useIssue } from "../../../hooks/useIssue";

const user = {
  name: "Whitney Francis",
  email: "whitney@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80",
};
const eventTypes = {
  issue_created: {
    content: "El caso fue abierto por",
    bgColorClass: "bg-slate-400",
  },
  comment_created: {
    content: "Recibió un comentario de",
    bgColorClass: "bg-blue-500",
  },
  offer_created: {
    content: "Recibió una oferta de",
    bgColorClass: "bg-green-500",
  },
};

const mockOffers = [
  {
    id: "1",
    lawyer: {
      name: "John Doe",
    },
    datetime: "2022-01-01T12:00:00Z",
    date: "Jan 1, 2022",
  },
];

const getIcons = (action) => {
  switch (action) {
    case "offer_created":
    case "issue_created":
      return <StarIcon className="h-5 w-5 text-white" aria-hidden="true" />;
    case "comment_created":
      return (
        <ChatBubbleBottomCenterTextIcon
          className="h-5 w-5 text-white"
          aria-hidden="true"
        />
      );
    default:
      return null;
  }
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Details = () => {
  const { issueId } = useParams();
  const navigate = useNavigate();

  const navigateTo = () => {
    navigate("/issues");
  };

  const {
    issue: {
      text,
      comments = [],
      offers = [],
      created_at: createdAt,
      events = [],
      customer: { profile: { name = "" } = {} } = {},
    },
    setNewComment,
    newComment,
    createComment,
    reject,
  } = useIssue(issueId, navigateTo);

  const [openOffer, setOfferVisibility] = useState(false);
  const [offer, setOffer] = useState({});

  return (
    <>
      <div className="min-h-full">
        <main className="py-10">
          {/* Page header */}
          <div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    className="h-16 w-16 rounded-full"
                    src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                    alt=""
                  />
                  <span
                    className="absolute inset-0 rounded-full shadow-inner"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-slate-900">{name}</h1>
                <p className="text-sm font-medium text-slate-500">
                  <time>{moment(createdAt).format("YYYY-MM-DD HH:mm")}</time>
                </p>
              </div>
            </div>
            <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3"></div>
          </div>

          <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-span-2 lg:col-start-1">
              {/* Description list*/}
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2
                      id="applicant-information-title"
                      className="text-lg font-medium leading-6 text-slate-900"
                    >
                      Información del caso
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-slate-500">
                      Detalles y adjuntos.
                    </p>
                  </div>
                  <div className="border-t border-slate-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-slate-500">
                          Acerca del caso
                        </dt>
                        <dd className="mt-1 text-sm text-slate-900">
                          <p className="mb-4">{text}</p>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </section>

              {/* Comments*/}
              <section aria-labelledby="notes-title">
                <div className="bg-white shadow sm:overflow-hidden sm:rounded-lg">
                  <div className="divide-y divide-slate-200">
                    <div className="px-4 py-5 sm:px-6">
                      <h2
                        id="notes-title"
                        className="text-lg font-medium text-slate-900"
                      >
                        Mensajes
                      </h2>
                    </div>
                    <div className="px-4 py-6 sm:px-6">
                      <ul className="space-y-8">
                        {comments.map(
                          ({
                            id,
                            text,
                            created_at: createdAt,
                            profile: { name },
                          }) => (
                            <li key={id}>
                              <div className="flex space-x-3">
                                <div className="flex-shrink-0">
                                  <img
                                    className="h-10 w-10 rounded-full"
                                    src={`https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80`}
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <div className="text-sm">
                                    <a
                                      href="/lawyer-profile"
                                      className="font-medium text-slate-900"
                                    >
                                      {name}
                                    </a>
                                  </div>
                                  <div className="mt-1 text-sm text-slate-700">
                                    <p>{text}</p>
                                  </div>
                                  <div className="mt-2 space-x-2 text-sm">
                                    <span className="font-medium text-slate-500">
                                      {moment(createdAt).fromNow()}
                                    </span>{" "}
                                    <span className="font-medium text-slate-500">
                                      &middot;
                                    </span>{" "}
                                    <button
                                      type="button"
                                      className="font-medium text-slate-600 rounded-md bg-slate-200 pt-1 pb-1 pl-2 pr-2"
                                    >
                                      Contestar
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="bg-slate-50 px-4 py-6 sm:px-6">
                    <div className="flex space-x-3">
                      <div className="flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-full"
                          src={user.imageUrl}
                          alt=""
                        />
                      </div>
                      <div className="min-w-0 flex-1">
                        <div>
                          <label htmlFor="comment" className="sr-only">
                            About
                          </label>
                          <textarea
                            id="comment"
                            name="comment"
                            rows={3}
                            onChange={({ target: { value } }) =>
                              setNewComment({ ...newComment, text: value })
                            }
                            className="block w-full rounded-md border-0 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:py-1.5 sm:text-sm sm:leading-6"
                            placeholder="Añade tu comentario aquí"
                            value={newComment.text}
                          />
                        </div>
                        <div className="mt-3 flex items-center justify-between">
                          <button
                            onClick={() => createComment()}
                            className="inline-flex items-center justify-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                          >
                            Responder
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <section
              aria-labelledby="timeline-title"
              className="lg:col-span-1 lg:col-start-3"
            >
              <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                <h2
                  id="timeline-title"
                  className="text-lg font-medium text-slate-900"
                >
                  Línea de eventos
                </h2>

                {/* Activity Feed */}
                <div className="mt-6 flow-root">
                  <ul className="-mb-8">
                    {events.map(({ id, action, created_at: createdAt }) => (
                      <li key={id}>
                        <div className="relative pb-8">
                          <div className="relative flex space-x-3">
                            <div>
                              <span
                                className={classNames(
                                  eventTypes[action].bgColorClass,
                                  "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                                )}
                              >
                                {getIcons(action)}
                              </span>
                            </div>
                            <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                              <div>
                                <p className="text-sm text-slate-500">
                                  {eventTypes[action].content}{" "}
                                  <span className="font-medium text-slate-900">
                                    {name}
                                  </span>
                                </p>
                              </div>
                              <div className="whitespace-nowrap text-right text-sm text-slate-500">
                                <time dateTime={createdAt}>
                                  {moment(createdAt).format("YYYY-MM-DD")}
                                </time>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {mockOffers.length > 0 && (
                <div className="bg-white mt-6 px-4 py-5 shadow sm:rounded-lg sm:px-6">
                  <h2
                    id="timeline-title"
                    className="text-lg font-medium text-slate-900"
                  >
                    Tu listado de ofertas
                  </h2>

                  {/* Activity Feed */}
                  <div className="mt-6 flow-root">
                    <ul>
                      {mockOffers.map((item) => (
                        <li key={item.id}>
                          <div
                            onClick={() => {
                              setOffer(item);
                              setOfferVisibility(true);
                            }}
                            className="relative pb-3 bg-gre"
                          >
                            <div className="relative flex space-x-3 bg-green-100 p-2 px-4 items-center rounded-lg shadow-md border border-emerald-400 cursor-pointer">
                              <div>
                                <span
                                  className={classNames(
                                    "bg-green-500",
                                    "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-green-200"
                                  )}
                                >
                                  <StarIcon
                                    className="h-5 w-5 text-white"
                                    aria-hidden="true"
                                  />
                                </span>
                              </div>
                              <div className="flex items-center min-w-0 flex-1 justify-between space-x-4 pt-1.5 pl-2">
                                <div className="flex flex-col">
                                  <p className="text-sm text-emerald-500">
                                    Nueva oferta de
                                  </p>
                                  <p className="font-medium text-emerald-700">
                                    {item.lawyer.name}
                                  </p>
                                </div>
                                <div className="whitespace-nowrap text-right text-sm text-emerald-700">
                                  <time dateTime={item.datetime}>
                                    {item.date}
                                  </time>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </section>
          </div>
        </main>
        <OfferModal
          openOffer={openOffer}
          setOpen={setOfferVisibility}
          offer={offer}
          issueId={issueId}
          reject={reject}
        />
      </div>
    </>
  );
};
