const mockLawyers = [
  {
    avatar:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    name: "Juan Pablo Gutierrez",
    email: "admin@gmail.com",
  },
  {
    avatar:
      "https://images.unsplash.com/photo-1519681393784-d120267933ba?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    name: "Maria Rodriguez",
    email: "maria@gmail.com",
  },
  {
    avatar:
      "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    name: "Pedro Perez",
    email: "pedro@gmail.com",
  },
];

const LawyerBadge = ({ email }) => {
  return (
    <div className="bg-emerald-100 border-2 border-emerald-200 rounded-md py-1 px-2 flex flex-row items-center space-x-2 cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-3 h-3 text-emerald-700"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>

      <p className="text-xs font-medium text-emerald-700">{email}</p>
    </div>
  );
};

export const PickedLawyerList = ({ lawyers = mockLawyers }) => (
  <div className="mt-2 flex flex-row flex-wrap gap-1">
    {lawyers.map((lawyer) => (
      <LawyerBadge key={lawyer.email} email={lawyer.email} />
    ))}
  </div>
);
