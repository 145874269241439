import axios from "axios";

export const fetchUser = async (payload) => { 
  try {
    const { data } = await axios.post('http://localhost:3000/login', payload);
    
    return {
      data,
      error: false
    };
  } catch ({ response: { data } }) {
    return {
      data: null,
      error: true
    };
  }
}

export const createUser = async (payload) => { 
  try {
    const { data } = await axios.post('http://localhost:3000/users', payload);
    
    return {
      data,
      error: false
    };
  } catch ({ response: { data } }) {
    return {
      data,
      error: true
    };
  }
}

export const requestResetUserPassword = async (payload) => { 
  try {
    const { data } = await axios.post('http://localhost:3000/users/reset', payload);
    
    return {
      data,
      error: false
    };
  } catch ({ response: { data } }) {
    return {
      data,
      error: true
    };
  }
}