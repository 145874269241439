import corfo from "../../images/social-proof/corfo.png";
import goan from "../../images/social-proof/goan.png";
import eri from "../../images/social-proof/eri.png";
import core from "../../images/social-proof/core.png";

const IMG = ({ path }) => (
  <div className="p-2 bg-slate-50 flex items-center justify-center rounded-md">
    <img
      className="max-h-12 w-full object-contain lg:col-span-1"
      src={path}
      alt="Transistor"
    />
  </div>
);

export default function Example() {
  return (
    <div className="bg-slate-800 py-24 sm:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <h2 className="text-3xl pb-4 font-semibold leading-8 text-white">
            Proyecto apoyado por instituciones nacionales
          </h2>
          <div className="space-y-2 mt-4 lg:mx-auto lg:mt-10 lg:grid lg:grid-cols-4 lg:items-start lg:gap-x-8 lg:gap-y-10 sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:grid-cols-4">
            <IMG path={corfo} />
            <IMG path={goan} />
            <IMG path={eri} />
            <IMG path={core} />
          </div>
        </div>
      </div>
    </div>
  );
}
