import { PaperClipIcon } from "@heroicons/react/20/solid";



export const TextArea = ({
  descriptionValue,
  onDescriptionChange,
  titleValue,
  onTitleChange,
  hasError,
}) => {
  const textAreaHasError = () => hasError && (titleValue === '' || descriptionValue.length < 50);

  return (
    <div className="w-full relative">
      <div className={`w-full overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500 ${textAreaHasError() && "border-red-600"}`}>
        <label htmlFor="title" className="sr-only">
          Título del caso
        </label>
        <input
          value={titleValue}
          onChange={onTitleChange}
          type="text"
          name="title"
          id="title"
          className="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-gray-400 focus:ring-0"
          placeholder="Ej: Pagos pendientes de arriendo"
        />
        <label className="sr-only">
          Descripcion
        </label>
        <textarea
          rows={5}
          onChange={onDescriptionChange}
          name="description"
          id="description"
          className="block w-full resize-none border-0 py-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          placeholder="Ej: Arrendé mi casa en el año 2019 y el arrendatario no ha pagado desde marzo 22..."
          value={descriptionValue}
        />

        {/* Spacer element to match the height of the toolbar */}
        <div aria-hidden="true" className="bg-white">
          {/*<div className="py-2">
            <div className="h-9" />
          </div>*/}
          <div className="h-px" />
          <div className="py-2">
            <div className="py-px">
              <div className="h-9" />
            </div>
          </div>
        </div>
      </div>

      <div className="absolute inset-x-px bottom-0">
        <div className={`flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3 ${textAreaHasError() && "border-red-600"}`}>
          <div className="flex">
            <button
              type="button"
              className="group -my-2 -ml-2 inline-flex items-center rounded-full px-3 py-2 text-left text-gray-400"
            >
              <PaperClipIcon
                className="-ml-1 mr-2 h-5 w-5 group-hover:text-gray-500"
                aria-hidden="true"
              />
              <span className="text-sm italic text-gray-500 group-hover:text-gray-600">
                Adjunta un archivo
              </span>
            </button>
          </div>
          <div className="text-sm italic text-gray-500 group-hover:text-gray-600">
            { textAreaHasError() ? (
              <span className="text-red-600">{ titleValue === '' ? "Titulo invalido" : "Descripción invalida"}</span>
            ) : (
              <span>Cantidad de caracteres: {descriptionValue.length}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
