import axios from "axios";

export const fetchLawyers = async (search) => {
  const url =`http://localhost:3000/lawyers?search=${search}` 
  try {
    const { data } = await axios.get(url);
    
    return {
      data,
      error: false
    };
  } catch ({ response: { data } }) {
    return {
      data: null,
      error: true
    };
  }
}

export const fetchLawyer = async (id) => {
  const url =`http://localhost:3000/lawyers/${id}`
  try {
    const { data } = await axios.get(url);
    
    return {
      data,
      error: false
    };
  } catch ({ response: { data } }) {
    return {
      data: null,
      error: true
    };
  }
}
