import {
  CheckCircleIcon,
  ChevronRightIcon,
  EnvelopeIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";

const applications = [
  {
    applicant: {
      name: "Ricardo Cooper",
      email: "ricardo.cooper@example.com",
      imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    date: "2020-01-07",
    dateFull: "Diciembre 16, 2022",
    stage: "Completed phone screening",
    href: "issue-detail",
    price: "350.000",
  },
  {
    applicant: {
      name: "Alejandra Ramos",
      email: "ale.ramos@example.com",
      imageUrl:
        "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    date: "2020-01-07",
    dateFull: "Agosto 23, 2022",
    stage: "Completed phone screening",
    href: "issue-detail",
    price: "160.000",
  },
  {
    applicant: {
      name: "Javier Mallea",
      email: "j.mallea@example.com",
      imageUrl:
        "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    date: "2020-01-07",
    dateFull: "Junio 22, 2022",
    stage: "Completed phone screening",
    href: "issue-detail",
    price: "50.000",
  },
];

export const LawyerPayments = () => {
  return (
    <div className="col-span-9 overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-slate-200">
        {applications.map((application) => (
          <li key={application.applicant.email}>
            <a className="block hover:bg-slate-50">
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="flex min-w-0 flex-1 items-center">
                  <span className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-green-200">
                    <CurrencyDollarIcon className=" text-green-400 w-8 h-8" />
                  </span>
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <p className="truncate text-md font-medium text-slate-600">
                        {application.applicant.name}
                      </p>
                      <p className="mt-2 flex items-center text-sm text-slate-500">
                        <EnvelopeIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-slate-300"
                          aria-hidden="true"
                        />
                        <span className="truncate">
                          {application.applicant.email}
                        </span>
                      </p>
                    </div>
                    <div className="hidden md:block">
                      <div>
                        <p className="text-sm text-slate-500">
                          <time dateTime={application.date}>
                            {application.dateFull}
                          </time>
                        </p>
                        <p className="mt-2 flex items-center text-sm text-slate-500">
                          <CheckCircleIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                            aria-hidden="true"
                          />
                          ${application.price}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
