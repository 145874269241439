import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useIssue } from "../../../hooks/useIssue";
import { TextArea } from "./case-text-area";
import { useCategories } from "../../../hooks/useCategories";

const Divider = ({ text }) => (
  <div className="relative">
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className="w-full border-t border-gray-300" />
    </div>
    <div className="relative flex justify-center">
      <span className="bg-white px-2 text-sm text-gray-500">{text}</span>
    </div>
  </div>
);

export const Heading = () => {
  const { categories } = useCategories();
  const { issue, setIssue, create, issueError } = useIssue();

  // const temporaryCategories = [
  //   { id: 1, name: "Derecho Civil" },
  //   { id: 2, name: "Derecho de Familia" },
  //   { id: 3, name: "Derecho del Trabajo" },
  //   { id: 4, name: "Policía Local" },
  //   { id: 5, name: "Otros" },
  // ];

  return (
    <div className="flex flex-col space-y-8">
      <div>
        <p className="text-lg font-medium text-slate-600">
          ¿Qué categoría se ajusta más a tu problema?
        </p>
        <div className="flex space-x-2 pt-2">
          {categories.map((category) => {
            const isActive = issue?.categoryId === category.id;
            return (
              <div
                key={`cateogry-${category.id}`}
                onClick={() => setIssue({ ...issue, categoryId: category.id })}
                className={`${
                  isActive && "bg-emerald-400  border-emerald-200"
                } cursor-pointer h-10 flex items-center justify-center px-4 border-2 rounded-full hover:scale-90`}
              >
                {console.log("category", category)}
                <p
                  className={`text-sm ${
                    isActive
                      ? "text-emerald-700 font-bold"
                      : "font-medium text-slate-500"
                  }`}
                >
                  {category.name}
                </p>
              </div>
            );
          })}
        </div>
        {issueError && issue.categoryId === "" && (
          <p className="text-small font-small text-red-600 mt-2">
            Selecciona una categoría
          </p>
        )}
      </div>

      <div>
        <p className="text-lg font-medium text-slate-600">
          Describe tu caso brevemente
        </p>
        <div className="flex flex-1 space-x-4 items-center">
          <div className="flex flex-1 mt-2">
            <TextArea
              hasError={issueError}
              titleValue={issue.title}
              onTitleChange={({ target: { value } }) =>
                setIssue({ ...issue, title: value })
              }
              descriptionValue={issue.text}
              onDescriptionChange={({ target: { value } }) => {
                setIssue({ ...issue, text: value });
              }}
            />
          </div>
          <div className="flex flex-1 flex-col">
            <p className="font-semibold text-slate-500">Ejemplo</p>
            <p className="text-slate-500">
              Estaba conduciendo en la calle Pajaritos a 60 km/h cuando una
              camioneta roja me chocó en el lado del pasajero. El otro conductor
              estaba enviando mensajes de texto y no vio la señal de pare. Tengo
              un esguince de muñeca y facturas médicas acumuladas. Poseo un
              video de cámara de tablero que demuestra que el otro conductor es
              el culpable.{" "}
            </p>
          </div>
        </div>
      </div>

      <button
        type="button"
        onClick={() => create()}
        className="inline-flex w-32 items-center gap-x-2 rounded-md bg-sky-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
        Crear caso
      </button>
    </div>
  );
};
