import hero from "../../images/hero/hero.jpg";
import logo from "../../images/logo-full.png";

export default function Example() {
  return (
    <div className="relative bg-white">
      <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
        <div className="px-6 pb-12 pt-10 sm:pb-32 lg:col-span-6 lg:px-0 xl:pb-56 xl:pt-48 xl:col-span-6">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <img className="w-32 lg:w-44" src={logo} alt="Your Company" />

            <h1 className="mt-10 lg:mt-24 text-4xl font-bold tracking-tight text-gray-900 sm:mt-10 sm:text-6xl">
              Obtén la ayuda legal que necesitas de forma anónima y segura
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Publica tus problemas legales, obtén respuestas de abogados y
              contrata al que mejor encaje con tu caso.
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <a
                href="/select-account-type"
                className="rounded-md bg-orange-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
              >
                Comienza aquí
              </a>
            </div>
          </div>
        </div>
        <div className="relative lg:col-span-6 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 ">
          <img
            className="lg:pt-16 lg:object-fit  lg:absolute lg:inset-0 lg:aspect-auto lg:my-auto xl:h-4/5"
            src={hero}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
