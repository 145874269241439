import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { useUser } from "./hooks/useUser";
import LandingPage from "./screens/landing";
import AuthPage from "./screens/user/auth";
import { UserDashboard } from "./screens/user/dashboard";
import { CreateIssue } from "./screens/user/create-issue";
import { LawyerProfile } from "./screens/user/lawyer-profile";
import { Issues } from "./screens/user/issues";
import { Lawyers } from "./screens/user/lawyer-list";
import { LawyerDashboard } from "./screens/lawyer/dashboard";
import { IssueDetail } from "./screens/user/issue-detail";
import { Checkout } from "./screens/user/checkout";
import { LawyerIssueList } from "./screens/lawyer/issue-list";
import { LawyerIssueDetail } from "./screens/lawyer/issue-detail";
import { LawyerCreateOffer } from "./screens/lawyer/create-offer";
import { LawyerUpdateOffer } from "./screens/lawyer/update-offer";
import { LawyerCaseOpen } from "./screens/lawyer/case-open";
import WebpayResponseHandler from "./screens/user/webpay";
import UserSignup from "./screens/user/signup";
import SelectAccountType from "./screens/user/select-account-type";
import LawyerSignup from "./screens/lawyer/signup";
import RequestResetPassword from "./screens/user/request-reset-password";
import SetNewPassword from "./screens/user/set-new-password";

export default function App() {
  const { user, isRehydrated } = useUser();

  const ProtectedRoute = ({ redirectPath = "/auth", children }) => {
    if (!user.token && isRehydrated) {
      return <Navigate to={redirectPath} replace />;
    }

    return children;
  };

  const AuthenticationRoute = ({ children }) => {
    const redirectPath =
      user.selectedProfile.profileable_type === "Customer"
        ? "/dashboard"
        : "/lawyer/dashboard";
    if (user.token && isRehydrated) {
      return <Navigate to={redirectPath} replace />;
    }

    return children;
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <LandingPage />,
    },
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute>
          <UserDashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: "/issue-detail/:issueId",
      element: (
        <ProtectedRoute>
          <IssueDetail />
        </ProtectedRoute>
      ),
    },
    {
      path: "/create-issue",
      element: (
        <ProtectedRoute>
          <CreateIssue />
        </ProtectedRoute>
      ),
    },
    {
      path: "/issues",
      element: (
        <ProtectedRoute>
          <Issues />
        </ProtectedRoute>
      ),
    },
    {
      path: "/publications/issue-detail",
      element: <IssueDetail />,
    },
    {
      path: "/webpay-handler",
      element: <WebpayResponseHandler />,
    },
    {
      path: "/lawyers",
      element: (
        <ProtectedRoute>
          <Lawyers />
        </ProtectedRoute>
      ),
    },
    {
      path: "/lawyer-profile/:lawyerId",
      element: (
        <ProtectedRoute>
          <LawyerProfile />
        </ProtectedRoute>
      ),
    },
    {
      path: "/issues/:issueId/offers/:offerId/checkout",
      element: <Checkout />,
    },
    {
      path: "/signup",
      element: (
        <AuthenticationRoute redirectPath="/dashboard">
          <UserSignup />
        </AuthenticationRoute>
      ),
    },
    {
      path: "/select-account-type",
      element: (
        <AuthenticationRoute redirectPath="/dashboard">
          <SelectAccountType />
        </AuthenticationRoute>
      ),
    },
    {
      path: "/request-reset-password",
      element: (
        <AuthenticationRoute redirectPath="/dashboard">
          <RequestResetPassword />
        </AuthenticationRoute>
      ),
    },
    {
      path: "/set-new-password",
      element: (
        <AuthenticationRoute redirectPath="/dashboard">
          <SetNewPassword />
        </AuthenticationRoute>
      ),
    },
    // Lawyer routes
    {
      path: "/lawyer/dashboard",
      element: (
        <ProtectedRoute>
          <LawyerDashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: "/lawyer/issue-detail/:issueId",
      element: (
        <ProtectedRoute>
          <LawyerIssueDetail />
        </ProtectedRoute>
      ),
    },
    {
      path: "/lawyer/case-open/:issueId",
      element: <LawyerCaseOpen />,
    },
    {
      path: "/lawyer/issue-list",
      element: (
        <ProtectedRoute>
          <LawyerIssueList />
        </ProtectedRoute>
      ),
    },
    {
      path: "/lawyer/create-offer/:issueId",
      element: (
        <ProtectedRoute>
          <LawyerCreateOffer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/lawyer/issue-detail/:issueId/offers/:offerId",
      element: (
        <ProtectedRoute>
          <LawyerUpdateOffer />
        </ProtectedRoute>
      ),
    },
    {
      path: "/lawyer/signup",
      element: <LawyerSignup />,
    },
    {
      path: "/auth",
      element: (
        <AuthenticationRoute redirectPath="/dashboard">
          <AuthPage />
        </AuthenticationRoute>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
}
