import { Layout } from "../../../components/layout/lawyer";
import { CreateOffer } from "./create-offer";

export const LawyerCreateOffer = () => {
  return (
    <Layout>
      <CreateOffer />
    </Layout>
  );
};
