import axios from "axios";

export const fetchIssues = async (myIssues, search) => { 
  const url = myIssues ? `http://localhost:3000/my/issues?search=${search}` : `http://localhost:3000/issues?search=${search}` 
  try {
    const { data } = await axios.get(url);
    
    return {
      data,
      error: false
    };
  } catch ({ response: { data } }) {
    return {
      data: null,
      error: true
    };
  }
};

export const createIssueOffer = async (id, payload) => {
  try {
    const { data } = await axios.post(`http://localhost:3000/issues/${id}/offers`, payload);

    return {
      data,
      error: false
    };
  } catch({ response: { data }}) {
    return {
      data: null,
      error: true
    }
  }
}

export const updateIssueOffer = async (issueId, id, payload) => {
  try {
    const { data } = await axios.put(`http://localhost:3000/issues/${issueId}/offers/${id}`, payload);

    return {
      data,
      error: false
    };
  } catch({ response: { data }}) {
    return {
      data: null,
      error: true
    }
  }
}

export const createIssueComment = async (id, payload) => {
  try {
    const { data } = await axios.post(`http://localhost:3000/issues/${id}/comments`, payload);

    return {
      data,
      error: false
    };
  } catch({ response: { data }}) {
    return {
      data: null,
      error: true
    }
  }
};

export const fetchOffer = async (id, offerId) => {
  try {
    const { data } = await axios.get(`http://localhost:3000/issues/${id}/offers/${offerId}`);

    return {
      data,
      error: false
    };
  } catch({ response: { data} }) {
    return {
      data: null,
      error: true
    };
  }
};

export const createPayment = async (issueId, offerId, payload) => {
  try {
    const { data } = await axios.post(`http://localhost:3000/issues/${issueId}/offers/${offerId}/payments`, payload);

    return {
      data,
      error: false
    };
  } catch({ response: { data} }) {
    return {
      data: null,
      error: true
    };
  }
};

export const fetchPaymentByTransbankToken = async (token) => {
  try {
    const { data } = await axios.post(`http://localhost:3000/payments/execute/${token}`);

    return {
      data,
      error: false
    };
  } catch({ response: { data} }) {
    return {
      data,
      error: true
    };
  }
}

export const rejectOffer = async (issueId, offerId) => {
  try {
    const { data } = await axios.put(`http://localhost:3000/issues/${issueId}/offers/${offerId}/reject`);

    return {
      data,
      error: false
    };
  } catch({ response: { data} }) {
    return {
      data: null,
      error: true
    };
  }
};

export const fetchIssue = async (id) => {
  try {
    const { data } = await axios.get(`http://localhost:3000/issues/${id}`);

    return {
      data,
      error: false
    };
  } catch({ response: { data} }) {
    return {
      data: null,
      error: true
    };
  }
};

export const createIssue = async (payload) => {
  try {
    const { data } = await axios.post('http://localhost:3000/issues', payload);

    return {
      data,
      error: false
    };
  } catch({ response: { data } }) {
    return {
      data: null,
      error: true
    };
  }
};
