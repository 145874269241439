import { Layout } from "../../../components/layout/lawyer";
import { IssueList } from "./issue-list";

export const LawyerIssueList = () => {
  return (
    <Layout>
      <div className="flex flex-col items-center">
        <IssueList />
      </div>
    </Layout>
  );
};
