import { Heading } from "./heading";
import { OpenProblems } from "./open-problems";

export const ColumnTwo = () => {
  return (
    <div className="col-span-9">
      <OpenProblems />{" "}
    </div>
  );
};
