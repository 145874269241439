import {
  CheckCircleIcon,
  ChevronRightIcon,
  EnvelopeIcon,
} from "@heroicons/react/20/solid";
import moment from "moment";
import { useIssues } from "../../../hooks/useIssues";
import notCasesFound from "../../../images/not-found/notOpenCases.jpg";
import { useNavigate } from "react-router-dom";

export const OpenProblems = () => {
  const { openIssues } = useIssues();
  const navigate = useNavigate();

  if (openIssues.length === 0)
    return (
      <div className="flex flex-col items-center justify-center">
        <img className="max-w-2xl" src={notCasesFound}></img>
        <p className="text-xl font-bold mt-2 text-slate-700">
          No tienes casos abiertos
        </p>
        <p className="text-md text-slate-500">
          Comienza revisando los casos abiertos por nuestra comunidad
        </p>
        <button
          onClick={() => navigate("/lawyer/issue-list")}
          type="button"
          className="mt-4 rounded-md bg-sky-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
        >
          Ver casos abiertos
        </button>
      </div>
    );
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {openIssues.map(
          ({
            id,
            assigned_at: assignedAt,
            created_at: createdAt,
            customer: { name = "", email = "" },
          }) => (
            <li key={id}>
              <a
                href={`/lawyer/issue-detail/${id}`}
                className="block hover:bg-gray-50"
              >
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="flex min-w-0 flex-1 items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-12 w-12 rounded-full"
                        src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                    </div>
                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <p className="truncate text-sm font-medium text-cyan-600">
                          {name}
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-500">
                          <EnvelopeIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="truncate">{email}</span>
                        </p>
                      </div>
                      <div className="hidden md:block">
                        <div>
                          <p className="text-sm text-gray-900">
                            Adjudicado en{" "}
                            {moment(assignedAt).format("YYYY-MM-DD")}
                          </p>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            <CheckCircleIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                              aria-hidden="true"
                            />
                            1
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ChevronRightIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </a>
            </li>
          )
        )}
      </ul>
    </div>
  );
};
