export default function Example() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Abordamos el lugar de trabajo como algo que enriquece nuestras vidas
            y aporta valor al mundo.
          </h2>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Denu, la plataforma innovadora que conecta a personas con asesoría
            legal de profesionales de manera fácil y anónima.
          </p>
        </div>
        <div className="mx-auto mt-16 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end">
          <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-slate-100 p-8 sm:w-3/4 sm:max-w-md sm:flex-row-reverse sm:items-end  md:max-w-none  lg:w-72 lg:flex-none lg:flex-col lg:items-start">
            <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">
              $1.5 mil millones
            </p>
            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
              <p className="text-lg font-semibold tracking-tight text-gray-900">
                Abordando el mercado legal chileno
              </p>
              <p className="mt-2 text-base leading-7 text-slate-600">
                Denu apunta al mercado legal chileno de $1.5 mil millones con
                soluciones accesibles y anónimas.
              </p>
            </div>
          </div>
          <div className="flex flex-col-reverse justify-between gap-y-8 gap-x-16 rounded-2xl bg-slate-700 p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-44">
            <p className="flex-none text-3xl font-bold tracking-tight text-white">
              15,000 abogados
            </p>
            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
              <p className="text-lg font-semibold tracking-tight text-white">
                Conectando con abogados en Chile
              </p>
              <p className="mt-2 text-base leading-7 text-slate-400">
                Denu, creando una red de abogados calificados
              </p>
            </div>
          </div>
          <div className="flex flex-col-reverse justify-between gap-y-8 gap-x-16 rounded-2xl bg-cyan-600 p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28">
            <p className="flex-none text-3xl font-bold tracking-tight text-white">
              60%
            </p>
            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
              <p className="text-lg font-semibold tracking-tight text-white">
                Abordando necesidades legales no atendidas
              </p>
              <p className="mt-2 text-base leading-7 text-cyan-200">
                Denu ayuda a cubrir el 60% de necesidades legales no atendidas
                en Chile.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
