const mockLawyers = [
  {
    avatar:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    name: "Juan Pablo Gutierrez",
    email: "admin@gmail.com",
  },
  {
    avatar:
      "https://images.unsplash.com/photo-1519681393784-d120267933ba?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    name: "Maria Rodriguez",
    email: "maria@gmail.com",
  },
  {
    avatar:
      "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    name: "Pedro Perez",
    email: "pedro@gmail.com",
  },
];

const LawyerCard = ({ lawyer }) => {
  const { avatar, name, email } = lawyer;
  return (
    <div className="border-2 rounded-md p-2 flex flex-row items-center space-x-2 cursor-pointer">
      <img className="inline-block h-8 w-8 rounded-full" src={avatar} alt="" />
      <div className="">
        <p className="text-xs font-medium">{name}</p>
        <p className="text-xs text-slate-500">{email}</p>
      </div>
    </div>
  );
};

export const LawyerList = ({ lawyers = mockLawyers }) => (
  <div className="mt-2 flex flex-row flex-wrap gap-1">
    {lawyers.map((lawyer) => (
      <LawyerCard key={lawyer.email} lawyer={lawyer} />
    ))}
  </div>
);
