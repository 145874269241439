import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useIssues } from "../../../hooks/useIssues";
import { SearchBar } from "../../../components/search-bar";
import { DropDown } from "../../../components/dropdown";
import noCases from "../../../images/no-cases.jpg";

export const IssuesList = () => {
  const navigate = useNavigate();
  const { openIssues, setSearch, search } = useIssues(false);
  return (
    <>
      <div className="flex items-center flex-row justify-between w-full">
        <div className="flex flex-start flex-col">
          <p className="text-3xl font-semibold mb-2 text-slate-800">
            Problematicas de la comunidad
          </p>
          <p className="text-md font-medium mb-4 max-w-lg text-slate-400">
            Conoce mas sobre las soluciones que encuentra la comunidad
          </p>
        </div>
        <div>
          <SearchBar search={search} onSearch={setSearch} />
          <div className="flex space-x-1">
            <DropDown
              filterLabel="Categoria"
              values={["Familia", "Civil", "Penal"]}
            />
            <DropDown
              filterLabel="Estado"
              values={["Nuevo", "En Progreso", "Terminado"]}
            />
            <DropDown
              filterLabel="Adjuntos"
              values={["Posee Adjuntos", "No Posee Adjuntos"]}
            />
          </div>
        </div>
      </div>
      <div className="h-4" />
      {openIssues.length === 0 && (
        <div className="flex flex-col max-w-screen-xl items-center space-y-3">
          <img src={noCases} className="w-96" />
          <p className="text-2xl font-bold text-center">Ups!</p>
          <p className="text-slate-500 text-center w-80">
            No hay resultados que coincidan con tu búsqueda, intenta con otros
            parámetros
          </p>
        </div>
      )}
      {openIssues.length >= 1 && (
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {openIssues.map(
            ({
              id,
              text,
              createdAt,
              title,
              interactions,
              customer: { name } = {},
            }) => (
              <li
                onClick={() => navigate(`/issue-detail/${id}`)}
                key={id}
                className="col-span-1 divide-y divide-gray-200 rounded-2xl bg-white shadow"
              >
                <div className="flex w-full items-center justify-between space-x-6 p-6">
                  <div className="flex-1 ">
                    <div className="flex items-center space-x-3">
                      <h3 className="truncate text-sm font-medium text-gray-900">
                        {title} - {moment(createdAt).format("YYYY-MM-DD")}
                      </h3>
                    </div>
                    <p className="mt-1 text-sm text-gray-500">{text}</p>
                    <span className="mt-4 inline-block flex-shrink-0 rounded-full bg-sky-100 px-2 py-0.5 text-xs font-medium text-sky-800">
                      Interacciones: {interactions}
                    </span>
                    <span className="mt-4 inline-block flex-shrink-0 rounded-full bg-slate-100 px-2 py-0.5 text-xs font-medium text-slate-800">
                      {name}
                    </span>
                  </div>
                </div>
              </li>
            )
          )}
        </ul>
      )}
    </>
  );
};
