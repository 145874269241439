import React from 'react';
import moment from 'moment';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { persistor, store } from "./store";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
moment().locale('es');

root.render(
  <React.StrictMode>
    <Provider store={store} >
      <PersistGate loading={true} persistor={persistor}>
        <App />
        <ToastContainer theme="colored"/>
      </PersistGate>  
    </Provider>
  </React.StrictMode>
 );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
