import { useState, useEffect } from "react"
import { fetchCategories } from "../api";


export const useCategories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await fetchCategories();

      if(!error){
        setCategories(data);
      }
    }

    fetchData();
  }, []);

  return {
    categories
  }
}
