import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { createIssue, fetchIssue, createIssueComment } from "../api";
import { rejectOffer } from "../api/issues";

export const useIssue = (issueId, callbackError) => {
  const [issue, setIssue] = useState({
    title: '',
    categoryId: '',
    text: ''
  });
  const [newComment, setNewComment] = useState({
    text: ''
  });
  const [issueError, setIssueError] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [isFetched, setIsFetched] = useState(false);

  const isLawyerAvailableToOffer = () => {
    return issue.offer?.id;
  }

  const create = async () => {
    const { text, title, categoryId } = issue;
    setIssueError(false);

    if(text.length >= 50 && title !== "" && categoryId !== "") {
      const { error } = await createIssue({
        ...issue,
        category_id: categoryId
      });
      
      if (!error) {
        setIssue({
          title: '',
          text: '',
          categoryId: ''
        });
        toast.success("Tu caso ha sido creado exitosamente");
      }
    } else {
      setIssueError(true);
    }
  };

  const createComment = async () => {
    const { text } = newComment;

    if (text !== '') {
      const { data, error } = await createIssueComment(issueId, newComment);

      if(!error) {
        setNewComment({ text: '' });
        setIssue({
          ...issue,
          comments: [...issue.comments, data]
        });
      }
    }
  };

  const reject = async (offerId) => {
    const { error } = await rejectOffer(issueId, offerId);

    if (!error) {
      setIssue({
        ...issue,
        offers: issue.offers.filter((of) => of.id !== offerId)
      })
    }
  }

  useEffect(() => {
    const fetchApiIssue = async () => {
      setFetchError(false);
      setIsFetched(false);
      const { data, error } = await fetchIssue(issueId);
      setIsFetched(true);

      if(!error){
        setIssue(data);
      } else {
        callbackError();
        setFetchError(true);
      }
    };

    if(issueId) fetchApiIssue(issueId);
  }, [issueId]);

  return {
    issue,
    setIssue,
    create,
    issueError,
    newComment,
    fetchError,
    setNewComment,
    createComment,
    isFetched,
    isLawyerAvailableToOffer,
    reject
  }
};
