import Dinero from "dinero.js";

export const OfferDetails = ({
  offer: {
    price = 0,
    milestones = 1
  }
}) => {
  const parsedPrice = parseInt(price) || 0;
  const parsedMilestone = parseInt(milestones) || 1;
  const finalPrice = parsedPrice * 100;
  const formattedPrice = Dinero({ amount: finalPrice });
  const pricePerMilestone = formattedPrice.divide(parsedMilestone);
  return (
    <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 py-4 px-4 sm:px-8">
      <span>Precio: {formattedPrice.toFormat('$0.0')}</span>
      <span>Cuotas: {pricePerMilestone.toFormat('$0.0')}</span>
    </div>
  )
}