import axios from "axios";

export const fetchCategories = async () => {
  try {
    const { data } = await axios.get('http://localhost:3000/categories');

    return {
      data,
      error: false
    }
  } catch({ response: { data }}) {
    return {
      data: null,
      error: true
    }
  }
}
