import { useState, useEffect } from "react";
import { fetchIssues } from "../api";

export const useIssues = (myIssues = true) => {
  const [openIssues, setOpenIssues] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await fetchIssues(myIssues, search);

      if(!error){
        setOpenIssues(data);
      }
    }

    fetchData();
  }, [myIssues, search]);

  return {
    openIssues,
    search,
    setSearch
  }
}
