import Dinero from 'dinero.js';

const setDineroAmount = (price = 0) => {
  const parsedPrice = parseInt(price) || 0;
  const finalPrice = parsedPrice * 100;
  return Dinero({ amount: finalPrice });
}

const setDineroMilestones = (price = 0, milestones = 1) => {
  const parsedMilestone = parseInt(milestones) || 1;
  return setDineroAmount(price).divide(parsedMilestone);
}

export const formatAmount = (price = 0) => {
  return setDineroAmount(price).toFormat('$0,0');
}

export const formatMilestone = (price = 0, milestones = 0) => {
  return setDineroMilestones(price, milestones).toFormat('$0,0');
}