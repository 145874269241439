import { Layout } from "../../../components/layout/app";
import { IssuesList } from "./issues-list";

export const Issues = () => {
  return (
    <Layout>
      <div className="flex flex-col items-center">
        <IssuesList />
      </div>
    </Layout>
  );
};
