import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

export const SearchBar = (props) => {
  const { search, onSearch } = props;
  return (
    <div className="relative bg-white mb-4 shadow-slate-700 drop-shadow-md rounded-lg w-88">
      <MagnifyingGlassIcon
        className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
        aria-hidden="true"
      />
      <input
        onChange={({ target: { value } }) => onSearch(value)}
        className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
        placeholder="Busca por ejemplo: Problemas de divorcio"
        value={search}
      />
    </div>
  );
};
