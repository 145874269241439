import cta from "../../images/cta.png";

export default function CallToActionTwo() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-12 lg:px-8 lg:my-44">
        <div className="flex flex-col-reverse relative isolate  bg-gradient-to-r from-blue-500 to-cyan-500 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 xl:px-24 lg:pt-8">
          <div className="pb-24 mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Regístrate hoy y obtén la asesoría legal que necesitas.
            </h2>
            <p className="mt-6 text-lg leading-8 text-blue-50">
              Únete a nuestra plataforma y resuelve tus problemas legales de
              manera confidencial y efectiva con la ayuda de nuestros abogados
              calificados. ¡No esperes más!
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              <a
                href="/select-account-type"
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-sky-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Registrate hoy
              </a>
            </div>
          </div>
          <div className="mb-12 relative mt-16  h-80 md:h-auto md:mt-0 md:mb-0 md: mt:0 lg:-mt-28">
            <img
              className="w-[720px] lg:absolute lg:w-[500px] lg:-right-24 lg:rounded-md "
              src={cta}
              alt="App screenshot"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
