import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import bg from "../../images/auth/bg.jpg";
import { useUser } from "../../hooks/useUser";
import Datepicker from "react-tailwindcss-datepicker";
import { RadioInput } from "../../components/radio-input";

const regions = [
  "Metropolitana de Santiago",
  "Arica y Parinacota",
  "Tarapacá",
  "Antofagasta",
  "Atacama",
  "Coquimbo",
  "Valparaíso",
  "Libertador General Bernardo O’Higgins",
  "Maule",
  "Ñuble",
  "Biobío",
  "Araucanía",
  "Los Ríos",
  "Los Lagos",
  "Aysén del General Carlos Ibáñez del Campo",
  "Magallanes y de la Antártica Chilena",
];

const LawyerSignup = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const handleValueChange = (newValue) => {
    console.log("newValue:", newValue);
    setValue(newValue);
  };

  const { create, schema, error, loading, initialValues } = useUser(regions);

  return (
    <div className="flex flex-1 items-center justify-center bg-gradient-to-r from-cyan-500 to-blue-500 h-screen">
      <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl mx-auto shadow-lg">
        <div className="px-4 py-6 sm:p-8">
          <p className="text-xl text-slate-700 mb-2 font-bold">
            Bienvenido a Denu!
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values) => {
              create({
                ...values,
                profileable_type: "customer",
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form
                className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6"
                onSubmit={handleSubmit}
              >
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Nombre
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="name"
                      placeholder="Ej: Alberto"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      id="first-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                    />
                    {errors.name && touched.name && (
                      <span className="text-red-600">{errors.name}</span>
                    )}
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Apellido
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Ej: Perez"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      id="last-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                    />
                    {errors.lastName && touched.lastName && (
                      <span className="text-red-600">{errors.lastName}</span>
                    )}
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email
                  </label>
                  <div className="mt-2">
                    <input
                      type="email"
                      name="email"
                      placeholder="Ej: correo@gmail.com"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      id="email"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                    />
                    {errors.email && touched.email && (
                      <span className="text-red-600">{errors.email}</span>
                    )}
                  </div>
                </div>

                <div className="sm:col-span-6 ">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                  >
                    Selecciona tus areas de expertiz
                  </label>
                  <RadioInput />
                </div>

                <div className="sm:col-span-6 ">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                  >
                    Año de egreso
                  </label>
                  <Datepicker
                    asSingle
                    useRange={false}
                    value={value}
                    onChange={handleValueChange}
                  />
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Región
                  </label>
                  <div className="mt-2">
                    <select
                      id="state"
                      name="state"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.state}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option value="">Seleccione una región</option>
                      {regions.map((region, index) => (
                        <option key={`${region}-${index}`} value={region}>
                          {region}
                        </option>
                      ))}
                    </select>
                    {errors.state && touched.state && (
                      <span className="text-red-600">{errors.state}</span>
                    )}
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="street-address"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Calle
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="street"
                      placeholder="Ej: Av. Providencia 1234"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.street}
                      id="street-address"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                    />
                    {errors.street && touched.street && (
                      <span className="text-red-600">{errors.street}</span>
                    )}
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Teléfono
                  </label>
                  <div className="mt-2 flex flex-row w-full items-center space-x-2">
                    <span>+56</span>
                    <input
                      type="text"
                      name="phone"
                      placeholder="9 1234 5678"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      id="phone"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                    />
                    {errors.phone && touched.phone && (
                      <span className="text-red-600">{errors.phone}</span>
                    )}
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Contraseña
                  </label>
                  <div className="mt-2">
                    <input
                      type="password"
                      name="password"
                      placeholder="********"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      id="password"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                    />
                    {errors.password && touched.password && (
                      <span className="text-red-600">{errors.password}</span>
                    )}
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="passwordConfirmation"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Repita Contraseña
                  </label>
                  <div className="mt-2">
                    <input
                      type="password"
                      name="passwordConfirmation"
                      placeholder="********"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.passwordConfirmation}
                      id="passwordConfirmation"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                    />
                    {errors.passwordConfirmation &&
                      touched.passwordConfirmation && (
                        <span className="text-red-600">
                          {errors.passwordConfirmation}
                        </span>
                      )}
                  </div>
                </div>
                {error && <span className="text-red-600">{error}</span>}
                <div className="col-span-6">
                  <div className="flex flex-row items-center justify-between">
                    <button
                      type="button"
                      onClick={() => navigate("/auth")}
                      className="flex flex-row items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-grey-500 border shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4 mr-1"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 19.5L8.25 12l7.5-7.5"
                        />
                      </svg>
                      Volver
                    </button>
                    <button
                      type="submit"
                      disabled={loading}
                      className="rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                    >
                      Registrar
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default LawyerSignup;
