import { InboxIcon, SparklesIcon } from "@heroicons/react/24/outline";
import sectionOne from "../../images/features/section1.jpg";
import sectionTwo from "../../images/features/section2.jpg";
import sectionThree from "../../images/features/section3.jpg";

export default function Features() {
  return (
    <div
      id="features"
      className="pb-12 relative overflow-hidden bg-white lg:pb-32"
    >
      <div className="relative">
        <div className="flex flex-col-reverse lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-52 lg:px-0">
            <div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Encuentra Profesionales Publicando Tus Problemas
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Publica tus problemas legales de forma anónima en nuestra
                  plataforma y recibe consejos de abogados calificados que se
                  preocupan por resolver tu problema. No más temor a ser juzgado
                  o a revelar información delicada: nuestra plataforma garantiza
                  la privacidad y la seguridad que necesitas para enfrentar tus
                  problemas legales con confianza.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="lg:-mr-48 lg:pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:px-0">
              <img
                className="w-full rounded-xl xl:absolute lg:left-0 lg:w-full lg:h-auto lg:mt-24 xl:max-w-none"
                src={sectionOne}
                alt="Inbox user interface"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24 lg:mt-4">
        <div className="flex flex-col-reverse lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className=" mx-auto max-w-xl px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0">
            <div>
              <div className="py-12">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Encuentra Tu Pareja Legal Perfecta
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Busca la mejor opción para tus necesidades legales. Navega a
                  través de las respuestas de los abogados a tu publicación y
                  elige la que más te identifica. Obtén servicios legales
                  personalizados y adaptados a tus requerimientos.
                </p>
                <div className="mt-6">
                  <a
                    href="/signup"
                    className="inline-flex rounded-lg bg-blue-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-blue-600 hover:bg-blue-700 hover:ring-blue-700"
                  >
                    Comienza aquí
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
            <div className="lg:-ml-48 lg:pr-4 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              <img
                className="lg:rounded-xl lg:absolute lg:right-0 lg:max-w-lg lg:mt-24 lg:w-auto"
                src={sectionTwo}
                alt="Customer profile user interface"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative pt-16 lg: pt:12">
        <div className="flex flex-col-reverse lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
            <div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Mantén Tu Privacidad Hasta Estar Listo
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Tu privacidad es importante. Mantén tu identidad oculta hasta
                  que te sientas cómodo compartiéndola con el abogado que
                  elijas. Abre comunicación directa solo cuando estés listo para
                  continuar. Nuestra plataforma protege tus datos personales y
                  solo los revela cuando tú lo decidas, dándote control total
                  sobre tu información y la seguridad de que solo las personas
                  que tú elijas tendrán acceso a ella.
                </p>
                <div className="mt-6">
                  <a
                    href="https://wa.me/56933700006"
                    className="items-center inline-flex rounded-lg bg-emerald-500 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-emerald-500 hover:bg-emerald-600 hover:ring-emerald-600"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                      />
                    </svg>
                    <p className="ml-2">Chatea aquí con Denu</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="lg:-mr-48 lg:pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:p-0">
              <img
                className="lg:rounded-xl lg:absolute lg:left-0 lg:h-96 lg:w-auto lg:mt-14"
                src={sectionThree}
                alt="Inbox user interface"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
