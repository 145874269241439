import { useState, useEffect } from "react";
import { fetchLawyers } from "../api";

export const useLawyers = () => {
  const [lawyers, setLawyers] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await fetchLawyers(search);

      if(!error){
        setLawyers(data);
      }
    }

    fetchData();
  }, [search]);

  return {
    lawyers,
    search,
    setSearch
  }
}
