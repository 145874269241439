import { useState } from "react";
import { Layout } from "../../../components/layout/lawyer";
import { SideBar } from "./side-bar";
import { ColumnTwo } from "./column-two";
import { LawyerPayments } from "./payments";
import { Account } from "./account.js";

const RenderComponent = ({ index }) => {
  switch (index) {
    case 0:
      return <ColumnTwo />;
    case 1:
      return <LawyerPayments />;
    case 2:
      return <Account />;
    default:
      return <div>1</div>;
  }
};

// TODO: Implement <NPSForm />
export const LawyerDashboard = () => {
  const [index, setIntex] = useState(0);
  return (
    <Layout>
      <div className="grid grid-cols-12 gap-8">
        <SideBar index={index} onClick={setIntex} />
        <RenderComponent index={index} />
      </div>
    </Layout>
  );
};
