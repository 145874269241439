import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createIssueOffer, fetchOffer, updateIssueOffer } from "../api";

export const useOffer = (issueId, categories, offerId = null) => {
  const navigate = useNavigate();

  const [offer, setOffer] = useState({
    price: 0,
    milestones: 1,
    service: '',
    resolutionTime: ''
  });
  const [offerError, setOfferError] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  
  const create = async () => {
    const {
      price,
      milestones,
      service,
      resolutionTime,
      categoryId
    } = offer;

    if(price !== '' && milestones !== '' && service !== '' && resolutionTime !== '') {
      const { error } = await createIssueOffer(issueId, {
        ...offer,
        category_id: categoryId ? categoryId : categories[0].id,
        resolution_time: resolutionTime
      });

      if(!error){
        toast.success("Tu oferta ha sido creada exitosamente");
        navigate(`/lawyer/issue-detail/${issueId}}`)
      }
    } else {
      setOfferError(true);
    }
  };

  const update = async () => {
    const {
      price,
      milestones,
      service,
      resolutionTime,
      categoryId,
      id
    } = offer;

    if(price !== '' && milestones !== '' && service !== '' && resolutionTime !== '') {
      const { error } = await updateIssueOffer(issueId, id, {
        ...offer,
        category_id: categoryId ? categoryId : categories[0].id,
        resolution_time: resolutionTime
      });

      if(!error){
        toast.success("Tu oferta ha sido actualizada exitosamente");
        navigate(`/lawyer/issue-detail/${issueId}}`)
      }
    } else {
      setOfferError(true);
    }
  };

  useEffect(() => {
    const fetchApiOffer = async () => {
      setFetchError(false);
      setIsFetched(false);
      const { data, error } = await fetchOffer(issueId, offerId);
      setIsFetched(true);

      if(!error){
        setOffer({ ...data, categoryId: data.category_id, resolutionTime: data.resolution_time });
      } else {
        setFetchError(true)
      }
    };

    if(offerId) fetchApiOffer(offerId);
  }, [offerId, issueId]);

  return {
    offer,
    create,
    setOffer,
    offerError,
    fetchError,
    isFetched,
    update
  }
};
