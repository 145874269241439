import { useSelector } from "react-redux";
import {
  HomeIcon,
  PencilIcon
} from "@heroicons/react/24/outline";

//{
//  name: "Cuenta",
//  href: "#",
//  icon: InboxIcon,
//  current: false,
//  count: "5",
//},
//{
//  name: "Pagos",
//  href: "#",
//  icon: CalendarIcon,
//  current: false,
//  count: "20+",
//}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// TODO: filter issues by created and assigned status
export const SideBar = ({ onClick, index }) => {
  const navigation = [
    {
      name: "Crear un nuevo caso",
      icon: PencilIcon,
      current: true
    },
    {
      name: "Problemas Abiertos",
      icon: HomeIcon,
      current: false
    }
  ];

  return (
    <div className="col-span-3">
      {navigation.map((item, i) => (
        <p
          key={item.name}
          onClick={() => onClick(i)}
          className={classNames(
            i === index
              ? "bg-gray-200 text-gray-900"
              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
            "flex items-center rounded-md px-3 py-2 text-sm font-medium"
          )}
          aria-current={item.current ? "page" : undefined}
        >
          <item.icon
            className={classNames(
              i === index ? "text-gray-500" : "text-gray-400",
              "-ml-1 mr-3 h-6 w-6 flex-shrink-0"
            )}
            aria-hidden="true"
          />
          <span className="truncate">{item.name}</span>
        </p>
      ))}
    </div>
  );
};
