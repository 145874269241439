import { LockClosedIcon } from "@heroicons/react/20/solid";
import { useUser } from "../../hooks/useUser";
import logo from "../../images/logo.png";
import bg from "../../images/auth/bg.jpg";
import { useNavigate } from "react-router-dom";

export default function Example() {
  const { user, setCurrentUser, getUser, error, loading } = useUser();
  const navigate = useNavigate();

  return (
    <>
      <div className="flex min-h-full  bg-gradient-to-r from-cyan-500 to-blue-500 items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full rounded-lg shadow bg-white p-16 max-w-lg space-y-8 lg:pb-24 xl:mb-44">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={logo}
              alt="Your Company"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Ingresa a tu cuenta
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              o{" "}
              <span
                onClick={() => navigate("/select-account-type")}
                className="cursor-pointer font-medium text-cyan-600 hover:text-cyan-500"
              >
                crea una nueva aquí
              </span>
            </p>
          </div>
          <div className="mt-8 space-y-6" method="POST">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={({ target: { value } }) =>
                    setCurrentUser({ ...user, email: value })
                  }
                  required
                  className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  onChange={({ target: { value } }) =>
                    setCurrentUser({ ...user, password: value })
                  }
                  required
                  className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                  placeholder="Password"
                />
              </div>
              <div className="text-center pt-2">
                <span className="text-red-600"> {error} </span>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <span
                  onClick={() => navigate("/request-reset-password")}
                  className="cursor-pointer font-medium text-cyan-600 hover:text-cyan-500"
                >
                  Olvidaste tu contraseña?
                </span>
              </div>
            </div>

            <div>
              <button
                disabled={loading}
                onClick={() => getUser()}
                className="group relative flex w-full justify-center rounded-md bg-cyan-600 py-3 px-3 text-sm font-semibold text-white hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
              >
                {loading ? (
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="h-5 w-5 text-sky-500 group-hover:text-sky-400 animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                ) : (
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-cyan-500 group-hover:text-cyan-400"
                      aria-hidden="true"
                    />
                  </span>
                )}
                Ingresar
              </button>{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
