import Footer from "./footer";
import Navbar from "./navbar";

export const Layout = ({ children, title }) => {
  return (
    <div className="min-h-full bg-slate-50">
      <Navbar />
      <div style={{ minHeight: "75vh" }} className="h-full pb-10 pt-4">
        <div className="h-full mx-auto max-w-7xl sm:px-6 lg:px-8">
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
};

Layout.defaultProps = {
  title: "Título del layout",
};
