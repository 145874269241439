const faqs = [
  {
    question:
      "¿Cómo funciona el proceso de publicación de un problema en la plataforma?",
    answer:
      "Para publicar un problema en nuestra plataforma, simplemente haz clic en el botón 'Publicar problema' y proporciona una descripción detallada del problema. Es importante que proporciones la mayor cantidad de información posible para que los abogados puedan entender mejor tu situación y brindarte una respuesta precisa.",
  },
  {
    question: "¿Puedo publicar mi problema de forma anónima?",
    answer:
      "Sí, nuestro servicio permite a los usuarios publicar sus problemas de forma anónima. Solo los abogados tienen acceso a tu información de contacto y solo cuando decides aceptar sus servicios.",
  },
  {
    question: "¿Cómo se seleccionan los abogados que responden a mi problema?",
    answer:
      " Los abogados que responden a tu problema son seleccionados en función de su experiencia y conocimientos en el área específica en la que se encuentra tu problema.",
  },
  {
    question: "¿Puedo elegir el abogado que responda a mi problema?",
    answer:
      "Sí, puedes seleccionar el abogado que desees de entre aquellos que hayan respondido a tu problema.",
  },
  {
    question: "¿Cómo se establece el precio por los servicios legales?",
    answer:
      "El precio por los servicios legales se acuerda directamente entre el usuario y el abogado. La plataforma no interviene en la negociación del precio.",
  },
  {
    question: "¿Cómo se asegura la confidencialidad de mi información?",
    answer:
      "Nos tomamos muy en serio la privacidad y la seguridad de la información de nuestros usuarios. La comunicación entre el usuario y el abogado se realiza a través de un sistema seguro dentro de la plataforma, lo que garantiza la confidencialidad de la información compartida.",
  },
  {
    question:
      "¿Cómo puedo asegurarme de que el abogado que seleccioné es confiable y calificado?",
    answer:
      "Nuestra plataforma solo permite a abogados con licencia y calificados responder a problemas legales. Además, puedes revisar las reseñas y comentarios de otros usuarios para asegurarte de que el abogado que seleccionaste tenga una buena reputación.",
  },
  {
    question:
      "¿Qué sucede si no estoy satisfecho con los servicios del abogado?",
    answer:
      "Si no estás satisfecho con los servicios del abogado, puedes informarnos y haremos todo lo posible para resolver el problema. Además, nuestra plataforma cuenta con un sistema de calificación y reseñas que permite a los usuarios compartir sus experiencias y ayudar a otros usuarios a tomar una decisión informada.",
  },
];

export default function Example() {
  return (
    <div id="faq" className="bg-white">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
              Preguntas frecuentes
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              No puedes encontrar la respuesta que buscabas? Comunícate con{" "}
              <a
                href="https://wa.me/56933700006"
                className="font-medium text-orange-600 hover:text-orange-500"
              >
                nuestro equipo de
              </a>{" "}
              soporte.
            </p>
          </div>
          <div className="mt-12 lg:col-span-2 lg:mt-0">
            <dl className="space-y-12">
              {faqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-lg font-medium leading-6 text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
