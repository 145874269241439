import { formatAmount, formatMilestone } from "../../../utils/formatAmount";

export const OfferDetails = ({ offer: { price = 0, milestones = 1 } }) => {
  return (
    <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 py-4 px-4 sm:px-8 bg-slate-25">
      <p className="text-slate-500 text-sm">
        Hitos: <span className="text-slate-900">{milestones}</span>
      </p>{" "}
      <p className="text-slate-500 text-sm">
        Cada hito:{" "}
        <span className="text-slate-900">
          {formatMilestone(price, milestones)}
        </span>
      </p>
      <p className="text-slate-500">
        Precio total:{" "}
        <span className="text-slate-900">
          {formatAmount(price)}
        </span>
      </p>
    </div>
  );
};
