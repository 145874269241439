import { useNavigate } from "react-router-dom";
import bg from "../../images/auth/bg.jpg";
import people from "../../images/people.jpg";
import lawyers from "../../images/lawyers.jpg";

const SelectAccountType = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-1 items-center justify-center bg-gradient-to-r from-cyan-500 to-blue-500 h-screen">
      <div className=" p-4 grid grid-cols-2 sm:rounded-xl mx-auto space-x-4">
        <div className="overflow-hidden bg-white flex flex-col space-y-1 items-center shadow-sm ring-1 ring-sky-900/5 p-4 rounded-lg">
          <img src={people} className="w-64" />
          <p className="text-2xl font-bold">Personas</p>
          <p className="text-slate-500 w-64 text-center">
            ¿Tienes un problema legal por resolver?
          </p>
          <div className="h-2" />
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded"
            onClick={() => navigate("/signup")}
          >
            Registrate aquí
          </button>
        </div>

        <div className="overflow-hidden bg-white flex flex-col space-y-1 items-center shadow-sm ring-1 ring-gray-900/5 p-4 rounded-lg">
          <img src={lawyers} className="w-64" />
          <p className="text-2xl font-bold">Abogados</p>
          <p className="text-slate-500 w-64 text-center">
            Brinda a una mano a las personas de nuestra comunidad
          </p>
          <div className="h-2" />
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded ">
            Registrate aquí
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectAccountType;
